import React, {useEffect, useState} from 'react';
import AuthLayout from "../layout/AuthLayout";
import {
    IonAvatar,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle, IonContent, IonIcon, IonItem,
    IonLabel, IonList,
    IonThumbnail, IonToggle
} from "@ionic/react";
import {useToggle} from "../../hooks/UseToggle";
import {logout} from "../../utility/Logout";
import {addCircleSharp, logOutSharp, optionsSharp, pencilSharp, removeCircleSharp} from "ionicons/icons";
import {Link} from "react-router-dom";
import axios from "axios";
import {baseUrl} from "../../global/Config";
import {useFetch} from "../../hooks/useFetch";
import LoadingAnimation from "../../components/LoadingAnimation";

function UserProfile() {
    const [isContactUs, setIsContactUs] = useState(true);
    const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));


    const handleContactUsContent = () => {
        setIsContactUs(!isContactUs);
    }
    const handlePrivacyPolicyContent = () => {
        setIsPrivacyPolicy(!isPrivacyPolicy);
    }

    let isOrganizer = null

    if(user){
        isOrganizer = user.isEventOrganizer
    }
    const [isEventOrganizer,setIsEventOrganizer] = useState(isOrganizer);


    const {isSpinning,toggleData} = useToggle(`api/user/account/change`);

    const handleChange = () => {
        const isOrganizer = !isEventOrganizer
        setIsEventOrganizer(isOrganizer);
        toggleData(isOrganizer);
    }

    const [data,isLoading,setData] = useFetch(`api/company`);



    return (
        <AuthLayout>
            {isLoading && <LoadingAnimation/>}
            <IonCard style={{position:"relative"}}>

                <IonAvatar className="ion-paddin">

                    {user && user.userImage &&
                        <img alt="Silhouette of a person's head"
                             src={user.userImage}
                             style={{padding:"5px"}}
                        />
                    }
                    {user && !user.userImage &&
                        <img alt="Silhouette of a person's head"
                             src="https://ionicframework.com/docs/img/demos/avatar.svg"
                             style={{padding:"5px"}}
                        />
                    }
                </IonAvatar>
                {user &&
                    <div style={{position:"absolute",right:"10px",top:"0"}}>
                        <Link to = {`/user/update/${user.id}`}><button className="custom-edit-button">
                            <IonIcon aria-hidden="true"
                                     icon={pencilSharp} size="small"
                                     className="text-orange"
                            />Update
                        </button>
                        </Link>
                    </div>
                }

                <IonCardContent color="danger" className="custom-single-user">
                    {user &&
                        <>
                            <div style={{fontSize:"1em"}} className="profile">
                                <div>
                                    <IonLabel>Full Name:</IonLabel> &nbsp;
                                    <IonLabel>{user.fullName}</IonLabel>
                                </div>
                                <div>
                                    <IonLabel>Email:</IonLabel> &nbsp;&nbsp;
                                    <IonLabel>{user.email}</IonLabel>
                                </div>
                                <div>
                                    <IonLabel>Account Type:</IonLabel> &nbsp;
                                    <IonLabel>{user.userType}</IonLabel>
                                </div>
                            </div>
                        </>
                    }

                </IonCardContent>
            </IonCard>

            {
                user && user.userType !=="STAFF" &&
                <div style={{padding:"10px"}}>
                    {/*<div className="custom-margin-bottom">*/}
                    {/*    <label className="toggle">*/}
                    {/*        <input className="toggle-checkbox"*/}
                    {/*               type="checkbox" checked={isEventOrganizer} onChange={handleChange}/>*/}
                    {/*        <div className="toggle-switch"></div>*/}
                    {/*        <span className="toggle-label text-white">Event Organizer</span>*/}
                    {/*    </label>*/}
                    {/*</div>*/}
                    {/*<IonToggle color="danger" >Event Organizer</IonToggle>*/}
                </div>
            }

            {data && <>
                <div>

                    {!isContactUs &&
                        <Link to ="#" onClick = {handleContactUsContent}>
                            <button className="custom-toggle-content-button">
                                <IonIcon aria-hidden="true"
                                         icon={addCircleSharp} size="large"
                                         className="ion-float-start"/> <span> Contact Us</span>
                            </button>
                        </Link>
                    }

                    {isContactUs &&
                        <Link to ="#" onClick = {handleContactUsContent}>
                            <button className="custom-toggle-content-button bg-orange">
                                <IonIcon aria-hidden="true"
                                         icon={removeCircleSharp} size="large"
                                         className="ion-float-start"/> <span> Contact Us</span>
                            </button>
                        </Link>
                    }

                    {isContactUs &&
                        <IonCard>
                            <IonCardContent className="custom-company-profile">
                                <div>
                                    <IonLabel>Company Name:</IonLabel> &nbsp;
                                    <IonLabel>{data.companyName}</IonLabel>
                                </div>
                                <div>
                                    <IonLabel>Company Email:</IonLabel> &nbsp;
                                    <IonLabel>{data.companyEmail}</IonLabel>
                                </div>
                                <div>
                                    <IonLabel>Company Phones:</IonLabel> &nbsp;
                                    <IonLabel>{data.companyPhoneNumbers}</IonLabel>
                                </div>
                                <div>
                                    <IonLabel>Company Address:</IonLabel> &nbsp;
                                    <IonLabel>{data.companyAddress}</IonLabel>
                                </div>
                                <div>
                                    <iframe style={{width : "100%", height:"250px"}}
                                            src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d191560.83606797215!2d${data.companyLatitude}!3d${data.companyLongitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a2ae52d441ab%3A0x899a0ba01aaace58!2sPark%20G%C3%BCell!5e0!3m2!1sen!2sng!4v1691746991785!5m2!1sen!2sng"
                                            style="border:0; width:100%; height:450px;" allowFullScreen="" loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade`} title="google  map">
                                    </iframe>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    }
                </div>

                <div>
                    {!isPrivacyPolicy &&
                        <Link to ="#" onClick = {handlePrivacyPolicyContent}>
                            <button className="custom-toggle-content-button">
                                <IonIcon aria-hidden="true"
                                         icon={addCircleSharp} size="large"
                                         className="ion-float-start"/> <span> Privacy Policy</span>
                            </button>
                        </Link>
                    }

                    {isPrivacyPolicy &&
                        <Link to ="#" onClick = {handlePrivacyPolicyContent}>
                            <button className="custom-toggle-content-button bg-orange">
                                <IonIcon aria-hidden="true"
                                         icon={removeCircleSharp} size="large"
                                         className="ion-float-start"/> <span> Privacy Policy</span>
                            </button>
                        </Link>
                    }

                    {isPrivacyPolicy &&
                        <IonCard>
                            <IonCardContent className="custom-company-profile">
                                {/*<h5>Privacy Policy</h5>*/}
                                <div>
                                    <IonLabel>{data.companyPrivacyPolicy}</IonLabel>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    }
                </div>
            </>
            }

            <Link to ="#" onClick = {logout}>
                <button className="custom-logout-button">
                    <IonIcon aria-hidden="true"
                             icon={logOutSharp} size="large"
                             className="ion-float-start" className="text-orange"/> <span> Logout</span>
                </button>
            </Link>

        </AuthLayout>
    );
}


export default UserProfile;