import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./App.css";
import GuestLayout from "../../layout/GuestLayout";
//import {paymentToken} from "../../../storage/TicketDetails";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_test_51NYx1MHA1BUS9I6s3EjaHwi4JYAWo2Z2oyKgF8WoTfVGZ0dCKu8mPwzS6zBQFcgPM19eybjZK399bGTYbfH0qExx00JkV3q4vS");

export default function Payment() {
    const paymentToken =  JSON.parse(localStorage.getItem("paymentToken"));
    //const [clientSecret, setClientSecret] = useState(paymentToken.clientSecrete);
    const clientSecret = paymentToken.clientSecrete;
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <GuestLayout>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            )}
        </GuestLayout>
    );
}