import React from 'react';
import AuthLayout from "../layout/AuthLayout";
import {useParams} from "react-router";
import {useFetch} from "../../hooks/useFetch";
import {IonCol, IonGrid, IonRow} from "@ionic/react";
import {bgClassColors} from "../../global/Config";

function SingleTicket() {
    const {ticketId} = useParams();
    const [ticket, isSpinning] = useFetch(`api/transaction-history/${ticketId}`);

    ticket && console.log(ticket)
    return (

        <AuthLayout>
            <h4>Ticket</h4>
            {
                ticket &&
                <IonGrid className = "record-single">
                    <IonRow className={bgClassColors[0]}>
                        <IonCol size="4">Guest Name</IonCol>
                        <IonCol>{ticket.buyerFullName}</IonCol>
                    </IonRow>
                    <IonRow className={bgClassColors[1]}>
                        <IonCol size="4">Guest Email</IonCol>
                        <IonCol >{ticket.buyerEmail}</IonCol>
                    </IonRow>
                    <IonRow className={bgClassColors[0]}>
                        <IonCol size="4">Ticket ID</IonCol>
                        <IonCol>{ticket.ticketOrderRef}</IonCol>
                    </IonRow>
                    <IonRow className={bgClassColors[1]}>
                        <IonCol size="4">Event Title</IonCol>
                        <IonCol >{ticket.eventTitle}</IonCol>
                    </IonRow>
                    <IonRow className={bgClassColors[0]}>
                        <IonCol size="4">Date</IonCol>
                        <IonCol >{ticket.eventDate}</IonCol>
                    </IonRow>
                    <IonRow className={bgClassColors[1]}>
                        <IonCol size="4">Time</IonCol>
                        <IonCol >{ticket.eventTime}</IonCol>
                    </IonRow>
                    <IonRow className={bgClassColors[0]}>
                        <IonCol size="4">Qty</IonCol>
                        <IonCol >{ticket.totalTickets}</IonCol>
                    </IonRow>
                    <IonRow className={bgClassColors[1]}>
                        <IonCol size="4">Total Price</IonCol>
                        <IonCol ><span dangerouslySetInnerHTML= {{ __html: ticket.totalCharges}}></span></IonCol>
                    </IonRow>
                    <IonRow className={bgClassColors[0]}>
                        <IonCol size="4">Status</IonCol>
                        <IonCol >{ticket.ticketType === "FREE"? "FREE" : ticket.paymentStatus}</IonCol>
                    </IonRow>

                </IonGrid>
            }

        </AuthLayout>
    );
}


export default SingleTicket;