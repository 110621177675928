// const eventFilterData = {
//     cityName: "City",
//     dateFrom: null,
//     dateTo: null,
//     sortBy: "Sort by"
// }

//const data = JSON.parse(localStorage.getItem("eventFilterData"));
// if(data){
//     if(data.cityName !== null){
//         eventFilterData["cityName"] = data.cityName
//     }
//     if(data.sortColumn !==null) {
//         eventFilterData["sortColumn"] = data.sortColumn;
//     }
//     if(data.dateFrom !== null) {
//         eventFilterData.dateFrom = data.dateFrom;
//     }
//     if(data.dateTo !==null){
//         eventFilterData.dateTo = data.dateTo;
//     }
// }

//

//export const data = ;
export const eventFilterRequestData = JSON.parse(localStorage.getItem("eventFilterData"))
