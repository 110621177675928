// import React from 'react';
// import {IonSelect, IonSelectOption} from "@ionic/react";
//
// function UseFetchCity() {
//     return (
//         <></>
//             // <IonSelect label="Outline select" labelPlacement="floating" fill="outline">
//             //     <IonSelectOption value="apple">Apple</IonSelectOption>
//             //     <IonSelectOption value="banana">Banana</IonSelectOption>
//             //     <IonSelectOption value="orange">Orange</IonSelectOption>
//             // </IonSelect>
//     );
// }
//
//
// export default UseFetchCity;

import axios from "axios";
import {baseUrl, headers} from "../global/Config";
import {useContext, useEffect, useState} from "react";
import {MyContext} from "../statemanagement/ComponentState";

export const useFetch = (url) => {
    const [responseData, setResponseData] = useState(null);
    const {isSpinning, setIsSpinning} = useContext(MyContext);

    useEffect(() => {
        setIsSpinning(true);
        axios.get(`${baseUrl}/${url}`, headers
        )
            .then(res => {
                const data = res.data
                setResponseData(data);
                setIsSpinning(false);
                console.log(data);
            })
            .catch(err => {
                console.log(err);
            });
    }, [url])

    return [responseData,isSpinning,setResponseData];
}