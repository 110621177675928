import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import {baseUrl, frontendUrl} from "../../../global/Config";
import TicketOrder from "../../ticket/TicketOrder";
import Invoice from "../../ticket/Invoice";
import {IonCard, IonCardContent} from "@ionic/react";
import {useHistory} from "react-router";
import {paymentToken} from "../../../storage/PaymentToken";
//import {paymentToken} from "../../../storage/TicketDetails";

export default function CheckoutForm() {
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    //const paymentToken =  JSON.parse(localStorage.getItem("paymentToken"));
    const user = JSON.parse(localStorage.getItem("user"));


    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${frontendUrl}/complete`,
        //redirect: 'if_required', // Use this option to only redirect if required
        receipt_email: user.email,
      },
    });
    //complete/${paymentToken.orderReferenceNum}
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    //history.push("/complete");
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    }
    else {
      setMessage("An unexpected error occurred.");
    }
    //history.push("/complete");
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
      <>
        <IonCard style={{color:"#000"}}>
          <IonCardContent>
            <Invoice />
          </IonCardContent>

        </IonCard>

        <div style={{display:"flex", justifyContent:"center",flexDirection:"column"}}>
          <form id="payment-form" onSubmit={handleSubmit}>
            {/*<LinkAuthenticationElement*/}
            {/*    id="link-authentication-element"*/}
            {/*    onChange={(e) => setEmail(e.target.value)}*/}
            {/*/>*/}
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
          </form>
        </div>
      </>


  );
}