import React, {useContext, useState} from 'react';
import {
    IonInput,
    IonButton,
    IonItem,
    IonLabel
} from '@ionic/react';
import {baseUrl, headers} from "../../global/Config";
import axios from "axios";
import Spinner from "../../components/Spinner";
import ResponseMessage from "../../components/ResponseMessage";
import {MyContext} from "../../statemanagement/ComponentState";
import {EventCategoryRequestData} from "../../data/request/EventCategoryRequestData";
import AuthLayout from "../layout/AuthLayout";
import {appSettings} from "../../global/AppSettings";

const NewEventCategory = () => {
    const [data, setData] = useState(EventCategoryRequestData);
    const [isSpinning, setIsSpinning] = useState(false);
    const {setIsOpen, setResponseMessage} = useContext(MyContext);

    // @ts-ignore
    const handleChange = (e) => {

        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    // @ts-ignore
    const handleFileChange = (e) => {
        const name = e.target.name;
        const value = e.target.files;
        setData({ ...data, [name]: value[0] });
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        addEventCategory();
    }

    // @ts-ignore
    const addEventCategory = () => {

        setIsSpinning(true);
        // @ts-ignore
        axios.post(`${baseUrl}/api/event-category`, data, headers
        )
            .then(res => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Successful !", res.data]);
            })
            .catch(err => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Error !", err]);
                console.log(err);
            });
    }

    return (
        <AuthLayout>
            <form onSubmit={handleSubmit}>
                <h4>New Event Category</h4>

                <IonItem>
                    <IonInput
                        label="Category Name *"
                        labelPlacement="floating"
                        type="text"
                        name = "eventCategoryName"
                        onIonChange = {handleChange}
                        required>
                    </IonInput>
                </IonItem>

                <IonItem>
                    {/*<IonInput*/}
                    {/*    label="Description"*/}
                    {/*    labelPlacement="floating"*/}
                    {/*    type="text"*/}
                    {/*    name="eventCategoryDescription"*/}
                    {/*    onIonChange = {handleChange}*/}
                    {/*    required>*/}
                    {/*</IonInput>*/}

                    <textarea
                        aria-label="Description"
                        name="eventCategoryDescription"
                        onChange = {handleChange}
                        rows = {appSettings.textAreaHeight}
                        required
                    >
                    </textarea>
                </IonItem>

                <IonItem>
                    <IonLabel>
                        Image
                    </IonLabel>
                    <input
                        type="file"
                        name="eventCategoryImage"
                        onChange={handleFileChange}
                    />
                </IonItem>

                <button shape="round"
                           color="danger"
                           expand="block"
                           type="submit"
                           className="ion-margin-top"
                >
                    Add Event Category
                    {isSpinning && <Spinner/>}
                </button>
            </form>
            <ResponseMessage/>
        </AuthLayout>
    );
};

export default NewEventCategory;
