import React from 'react';
import {IonSpinner} from "@ionic/react";

function LoadingAnimation() {
    return (
        <div style={{position:"absolute",
            zIndex:9999, top:"50%",left:"50%"}}>
            <IonSpinner name="circular" className="text-white"></IonSpinner>
        </div>
    );
}



export default LoadingAnimation;