import React from 'react';
import {IonGrid, IonRow, IonCol, IonButton} from '@ionic/react';
import AuthLayout from "../layout/AuthLayout";
import {Link} from "react-router-dom";
import {useFetch} from "../../hooks/useFetch";
import {bgClassColors} from "../../global/Config";
import LoadingAnimation from "../../components/LoadingAnimation";

const TicketList = () => {

    const [responseData, isSpinning] = useFetch(`api/transaction-history`);

    return (
        <AuthLayout>
            {isSpinning && <LoadingAnimation/>}
            <IonGrid className = "record">
                <h4>All Tickets</h4>
                <IonRow>
                    <IonCol size="5">Ticket ID</IonCol>
                    <IonCol size="1">Qty</IonCol>
                    <IonCol size={3}>Price</IonCol>
                    <IonCol>Action</IonCol>
                </IonRow>
                {/*@ts-ignore*/}
                {responseData && responseData.ticketOrders.map((ticket, key)=>(
                    <IonRow key ={key} className={bgClassColors[key%2]}>
                        <IonCol size="5">{ticket["ticketOrderRefBarcode"]}</IonCol>
                        <IonCol size="1">{ticket["totalTickets"]}</IonCol>
                        <IonCol size={3}><span dangerouslySetInnerHTML= {{ __html: ticket["totalCharges"]}}></span></IonCol>
                        <IonCol>
                            <Link to={`/ticket/${ticket["id"]}`}>
                                <div className="custom-action-red">View</div>
                            </Link>
                        </IonCol>
                    </IonRow>
                ))}

            </IonGrid>
        </AuthLayout>
    );
};

export default TicketList;
