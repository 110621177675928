import React from 'react';
import {IonGrid, IonRow, IonCol, IonButton, IonCard, IonCardContent} from '@ionic/react';
import AuthLayout from "../layout/AuthLayout";
import {Link} from "react-router-dom";
import {useFetch} from "../../hooks/useFetch";
import {bgClassColors} from "../../global/Config";
import LoadingAnimation from "../../components/LoadingAnimation";

const MyTicket = () => {

    const [responseData, isSpinning] = useFetch(`api/user-transaction-receipt`);

    return (
        <AuthLayout>
            {isSpinning && <LoadingAnimation/>}
            <h4 className="text-white">My Tickets</h4>
                {responseData && responseData.ticketOrders.map((ticket, key)=>(
                    <IonCard key = {key} style={{color:"black"}}>
                        <IonCardContent>
                            <IonGrid className = "record">
                                <IonRow className={bgClassColors[0]}>
                                    {/*<IonCol size="4">QR Code</IonCol>*/}
                                    <IonCol className = "custom-qrcode-image"><img src={`${ticket["ticketOrderRefBarcode"]}`}/></IonCol>  </IonRow>
                                <IonRow className={bgClassColors[1]}>
                                    <IonCol size="4">Event Title</IonCol>
                                    <IonCol >{ticket["eventTitle"]}</IonCol>
                                </IonRow>
                                <IonRow className={bgClassColors[0]}>
                                    <IonCol size="4">Date</IonCol>
                                    <IonCol >{ticket["eventDate"]}</IonCol>
                                </IonRow>
                                <IonRow className={bgClassColors[1]}>
                                    <IonCol size="4">Time</IonCol>
                                    <IonCol >{ticket["eventTime"]}</IonCol>
                                </IonRow>
                                <IonRow className={bgClassColors[0]}>
                                    <IonCol size="4">Qty</IonCol>
                                    <IonCol >{ticket["totalTickets"]}</IonCol>
                                </IonRow>
                                <IonRow className={bgClassColors[1]}>
                                    <IonCol size="4">Total Price</IonCol>
                                    <IonCol ><span dangerouslySetInnerHTML= {{ __html: ticket["totalCharges"]}}></span></IonCol>
                                </IonRow>
                                <IonRow className={bgClassColors[0]}>
                                    <IonCol size="4">Status</IonCol>
                                    <IonCol >{ticket["paymentStatus"]}</IonCol>
                                </IonRow>

                            </IonGrid>
                        </IonCardContent>
                    </IonCard>

                ))}
        </AuthLayout>
    );
};

export default MyTicket;
