import React from 'react';
import {IonGrid, IonRow, IonCol } from '@ionic/react';
import AuthLayout from "../layout/AuthLayout";
import {Link} from "react-router-dom";
import {useFetch} from "../../hooks/useFetch";
import {bgClassColors} from "../../global/Config";
import LoadingAnimation from "../../components/LoadingAnimation";

const EventCategoryList = () => {

    const [responseData, isSpinning,setResponseData] = useFetch(`api/event-categories`);

    return (
        <AuthLayout>
            {isSpinning && <LoadingAnimation/>}
            <IonGrid className = "record-main">
                <h4>All Event Categories</h4>
                {/*<IonRow>*/}
                {/*    <IonCol>Name</IonCol>*/}
                {/*    <IonCol>Image</IonCol>*/}
                {/*    <IonCol>Action</IonCol>*/}
                {/*</IonRow>*/}
                {/*@ts-ignore*/}
                {responseData && responseData.eventCategories.map((eventCategory, key)=>(
                    <IonRow key ={key} className={bgClassColors[key%2]}>
                        <IonCol>{eventCategory["eventCategoryName"]}</IonCol>
                        <IonCol>
                            <img src = {eventCategory["eventCategoryImage"]} alt="Event category"/>
                        </IonCol>
                        <IonCol>
                            <Link to = {`/event-category-update/${eventCategory["id"]}`} className="custom-action-red">
                                Update
                            </Link>
                        </IonCol>
                    </IonRow>
                ))}

            </IonGrid>
        </AuthLayout>
    );
};

export default EventCategoryList;
