// import React, { useRef, useState } from 'react';
//
// function EmailVerification() {
//     const [values, setValues] = useState(['', '', '', '', '']);
//     const inputRefs = useRef([]);
//
//     const handleInputChange = (index, event) => {
//         const newValues = [...values];
//         newValues[index] = event.target.value;
//         setValues(newValues);
//
//         // Move focus to the next input if available
//         if (index < inputRefs.current.length - 1 && event.target.value.length === 1) {
//             inputRefs.current[index + 1].focus();
//         }
//
//         // Check if all inputs are filled
//         if (newValues.every(value => value !== '')) {
//             console.log('All inputs are filled');
//         }
//     };
//
//     return (
//         <div>
//             <form className="custom-verification">
//                 <div>
//                     {values.map((value, index) => (
//                         <input
//                             type="number"
//                             key={index}
//                             value={value}
//                             onChange={(e) => handleInputChange(index, e)}
//                             ref={(ref) => (inputRefs.current[index] = ref)}
//                         />
//                     ))}
//                 </div>
//             </form>
//         </div>
//     );
// }
//
// export default EmailVerification;

import React, { useRef, useState } from 'react';
import GuestLayout from "../layout/GuestLayout";
import GuestLayout3 from "../layout/GuestLayout3";
import {IonCardTitle} from "@ionic/react";

function EmailVerification() {
    const [values, setValues] = useState(['', '', '', '', '']);
    const inputRefs = useRef([]);

    const handleInputChange = (index, event) => {
        const newValues = [...values];
        newValues[index] = event.target.value;
        setValues(newValues);

        // Move focus to the next input if available
        if (index < inputRefs.current.length - 1 && event.target.value.length === 1) {
            inputRefs.current[index + 1].focus();
        }

        // Check if all inputs are filled
        if (newValues.every(value => value !== '')) {
            console.log('All inputs are filled');
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('Text');
        const pastedDigits = pastedText.replace(/\D/g, ''); // Remove non-digit characters
        const newValues = [...values];

        for (let i = 0; i < values.length; i++) {
            if (i === 0 && pastedDigits.length > 0) {
                newValues[i] = pastedDigits[0];
            } else {
                newValues[i] = pastedDigits[i];
            }
        }

        setValues(newValues);

        // Fill input fields
        newValues.forEach((value, index) => {
            if (inputRefs.current[index]) {
                inputRefs.current[index].value = value;
            }
        });

        // Move focus to the next input if available
        if (newValues.length > 0) {
            inputRefs.current[0].focus();
        }

        // Check if all inputs are filled
        if (newValues.every(value => value !== '')) {
            console.log('All inputs are filled');
        }
    };

    return (
        <GuestLayout3>
            <div style={{display:"flex", height:"80vh",alignItems:"center",justifyContent:"center"}}>
                <form className="custom-verification">
                    <IonCardTitle>Email Verification </IonCardTitle>
                    <span className="text-orange" style={{fontSize:"15px"}}>Enter the verification code here</span>
                    <div>
                        {values.map((value, index) => (
                            <input
                                type="number"
                                key={index}
                                value={value}
                                onChange={(e) => handleInputChange(index, e)}
                                onPaste={handlePaste}
                                ref={(ref) => (inputRefs.current[index] = ref)}
                            />
                        ))}
                    </div>
                </form>
            </div>

        </GuestLayout3>
    );
}

export default EmailVerification;

