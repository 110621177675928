import React, {useContext, useEffect, useState} from 'react';
import "../../assets/css/custom.css";
import {
    IonContent,
    IonHeader, IonIcon, IonItem,
    IonMenu,
    IonPage, IonSearchbar,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {useHistory} from "react-router";
import {useSearchBox} from "../../hooks/useSearchBox";
import Header from "./Header";
import {Link} from "react-router-dom";
import {logout} from "../../utility/Logout";
import {tokenFromStorage} from "../../global/Config";
import {MyContext} from "../../statemanagement/ComponentState";
import FooterTabs from "./FooterTabs";
import {optionsSharp} from "ionicons/icons";
import HomeRoute from "../../utility/HomeRoute";
function GuestLayout({children}) {

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function(position) {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);

                longitude && localStorage.setItem("location", JSON.stringify(
                    {latitude:latitude,longitude:longitude}
                ));
            });

        } else {
            console.log("Geolocation is not available.");
        }
    }, []);


    const {isOpen, setIsOpen} = useContext(MyContext);

    const history = useHistory();

    //HIDE SEARCH BAR ON SOME PAGES
    const {isSearchBox, setIsSearchBox} = useContext(MyContext);
    const isOpenSearchBox = useSearchBox(history.location.pathname)

    useEffect(() => {
        setIsSearchBox(isOpenSearchBox);
    },[isSearchBox,isOpenSearchBox,setIsSearchBox])

    const handleChange = (e) => {
        const search = e.target.value;
        history.push(`/even-by-search/${search}`); // Navigate to the search page
    }

    const handleFilterClick = () => {
        setIsOpen(true);
    }


    const blackListedRoute = ["/complete","/payment","/checkout"];
    const currentRoute = history.location.pathname;
    let showSearchBar = true;

    if(blackListedRoute.includes(currentRoute)){
        showSearchBar = false;
    }


    return (
        <div style={{backgroundColor:"002e31"}}>
            <IonMenu contentId="main-content" style={{backgroundColor:"002e31"}}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle><HomeRoute /></IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding" style={{backgroundColor:"002e31"}}>
                    {/*<EventFilter />*/}
                    {tokenFromStorage &&
                        <>
                            <IonItem >
                                <a href = "/dashboard">
                                    Dashboard
                                </a>
                            </IonItem>
                            <IonItem >
                                <Link to = "#" onClick = {logout}>
                                    Logout
                                </Link>
                            </IonItem>
                        </>
                    }
                </IonContent>
            </IonMenu>
            <IonPage id="main-content" style={{backgroundColor:"002e31"}}>
                <Header />
                <IonContent className="ion-padding" style={{backgroundColor:"002e31"}}>
                    {showSearchBar &&
                        <div className="ion-margin-bottom custom-search-bar-layout">
                            <div className="custom-search-bar">
                                <IonSearchbar className=""
                                              onIonChange ={handleChange} style={{width:"100%"}}>
                                </IonSearchbar>
                            </div>
                            <div className="custom-filter">
                                <Link to="/event-filter">

                                        {/*<img src={filterIcon} alt="Filter Icon" />*/}
                                        <IonIcon aria-hidden="true"
                                                 icon={optionsSharp} size="large"
                                                 style={{position:"absolute",right:"5px",top:"0",color:"#92949c"}}
                                        >

                                        </IonIcon>
                                </Link>
                            </div>

                        </div>
                    }

                    {children}
                </IonContent>
                <FooterTabs />
                {/*{*/}
                {/*    isOpen && <EventFilterModal/>*/}
                {/*}*/}
            </IonPage>
        </div>
    );
}
export default GuestLayout;