import React from 'react';
import {IonGrid, IonRow, IonCol } from '@ionic/react';
import AuthLayout from "../layout/AuthLayout";
import {Link} from "react-router-dom";
import {useFetch} from "../../hooks/useFetch";
import Spinner from "../../components/Spinner";
import {bgClassColors} from "../../global/Config";
import LoadingAnimation from "../../components/LoadingAnimation";

const CityList = () => {
    //const [cities, setCities] = useState(null);

   // useEffect(() => {
   //     getAllCities();
   // },[])


    const [data, isSpinning] = useFetch(`api/cities`);

    return (
        <AuthLayout>
            {isSpinning && <LoadingAnimation/>}
            <IonGrid className = "record-main">
                <h4>All Cities</h4>
                {/*@ts-ignore*/}
                {data && data.cities.map((city, key)=>(
                    <IonRow key ={key} className={bgClassColors[key%2]}>
                        <IonCol>{city["cityName"]}</IonCol>
                        <IonCol>
                            <img src = {city["cityImageUrl"]} alt="City"/>
                        </IonCol>
                        <IonCol>
                            <Link to = {`/city-update/${city["id"]}`} className="custom-action-red">
                                Update
                            </Link>
                        </IonCol>

                    </IonRow>
                ))}

            </IonGrid>
            {isSpinning &&
                <div><Spinner /></div>
            }
        </AuthLayout>
    );
};

export default CityList;
