// @ts-ignore
export const openCity = (e , setIsCustomCity ) => {
    if(e.target.name === "city"){
        if (e.target.value === "Others") {
            setIsCustomCity(true);
        }
        else{
            setIsCustomCity(false);
        }
    }
}