import React, {useContext, useState} from 'react';
import {
    IonInput,
    IonButton,
    IonItem,
    IonLabel
} from '@ionic/react';
import {baseUrl, headers} from "../../global/Config";
import axios from "axios";
import Spinner from "../../components/Spinner";
import ResponseMessage from "../../components/ResponseMessage";
import {MyContext} from "../../statemanagement/ComponentState";
import AuthLayout from "../layout/AuthLayout";
import {CityRequestData} from "../../data/request/CityRequestData";

const NewCity = () => {
    const [data, setData] = useState(CityRequestData);
    const [isSpinning, setIsSpinning] = useState(false);
    const {setIsOpen, setResponseMessage} = useContext(MyContext);

    // @ts-ignore
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    // @ts-ignore
    const handleFileChange = (e) => {
        const name = e.target.name;
        const value = e.target.files;
        setData({ ...data, [name]: value[0] });
    };

    // @ts-ignore
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        addCity();
    }

    // @ts-ignore
    const addCity = () => {
        setIsSpinning(true);
        // @ts-ignore
        axios.post(`${baseUrl}/api/city`, data, headers
        )
            .then(res => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Successful !", res.data]);
            })
            .catch(err => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Error !", err.response.data.message]);
                console.log(err);
            });
    }

    return (
        <AuthLayout>
            <form onSubmit={handleSubmit}>
                <h2>New City</h2>

                <IonItem>
                    <IonInput
                        label="City Name *"
                        labelPlacement="floating"
                        type="text"
                        name = "cityName"
                        onIonChange = {handleChange}
                        required>
                    </IonInput>
                </IonItem>

                <IonItem>
                    <IonLabel>
                        City Image
                    </IonLabel>
                    <input
                        type="file"
                        name="cityImageUrl"
                        onChange={handleFileChange}
                    />
                </IonItem>


                <button shape="round"
                           color="danger"
                           expand="block"
                           type="submit"
                           className="ion-margin-top"
                >
                    Add City
                    {isSpinning && <Spinner/>}
                </button>
            </form>
            <ResponseMessage/>
        </AuthLayout>
    );
};

export default NewCity;
