import React, {useContext} from 'react';
import {IonAlert, useIonRouter} from '@ionic/react';
import {MyContext} from "../statemanagement/ComponentState";
import {useHistory} from "react-router";

function ResponseMessage() {

    const ionRouter = useIonRouter();
    const history = useHistory();

    const blackListedRoute = ["/complete","/payment","/checkout","/login", "/register"];
    const currentRoute = history.location.pathname;


    // @ts-ignore
    const {
        isOpen,
        setIsOpen,
        responseMessage
    } = useContext(MyContext);



    const handleOnDismissedEvent = () => {
        setIsOpen(false);

        if(!blackListedRoute.includes(currentRoute)){
            ionRouter.push(ionRouter.routeInfo.lastPathname); // This will go back to the previous page
        }

    }

    return (
        <>
            <IonAlert
                isOpen={isOpen}
                header={responseMessage[0]}
                message={responseMessage[1]}
                buttons={['OK']}
                onDidDismiss={handleOnDismissedEvent}
            ></IonAlert>
        </>
    );
}
export default ResponseMessage;