import {useContext,useState} from "react";
import axios from "axios";
import {baseUrl, headers} from "../global/Config";
import {MyContext} from "../statemanagement/ComponentState";

export const useToggle = (url) => {

    const {setIsOpen, setResponseMessage, isSpinning, setIsSpinning} = useContext(MyContext);
    const [responseData, setResponseData] = useState(null);

    const toggleData = () => {
        setIsSpinning(true);
        axios.get(`${baseUrl}/${url}`, headers)
            .then(res => {
                console.log(res);
                setIsSpinning(false);
                localStorage.setItem("user", JSON.stringify(res.data));
                window.location.href = "/my-profile"
                //setIsOpen(true);
               // setResponseMessage(["Successful !", res.data]);
                //setResponseData(res.data);
            })
            .catch(err => {
                setIsSpinning(false);
                setIsOpen(true);
                //setResponseMessage(["Error !", err.response.data.message]);
                console.log(err);
            });
    }
    return { isSpinning,responseData, toggleData};

}