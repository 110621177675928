import React, {useEffect, useState} from 'react';
import axios from "axios";
import {baseUrl, headers} from "../../global/Config";
import header from "../layout/Header";
import GuestLayout from "../layout/GuestLayout";
import FilteredEvent from "../filteredevents/FilteredEvent";
import LoadingAnimation from "../../components/LoadingAnimation";

function FavouriteEvent() {
    const [favouriteEvents, setFavouriteEvents] = useState(null);
    const [isSpinning, setIsSpinning] = useState(true);

    const  eventIds =  JSON.parse(localStorage.getItem("eventKeys")) || [];

    useEffect(() => {
        axios.post(`${baseUrl}/api/favourite-event`,{favourite:eventIds}, headers)
            .then(res => {
                console.log(res.data.events)
                setFavouriteEvents(res.data.events);
                setIsSpinning(false)
            })
            .catch(err => {

            })
    }, [])
    return (
        <GuestLayout>
            {isSpinning && <LoadingAnimation/>}
            {favouriteEvents && <FilteredEvent events = {favouriteEvents}/>}
        </GuestLayout>
    );
}

export default FavouriteEvent;