import React from 'react';
import {IonGrid, IonRow, IonCol } from '@ionic/react';
import AuthLayout from "../layout/AuthLayout";
import {Link} from "react-router-dom";
import {useFetch} from "../../hooks/useFetch";
import {bgClassColors} from "../../global/Config";
import LoadingAnimation from "../../components/LoadingAnimation";

const EventList = () => {

    const [responseData, isSpinning,setResponseData] = useFetch(`api/events/*`);


    return (
        <AuthLayout>
            {isSpinning && <LoadingAnimation/>}

            <IonGrid className = "record-main">
                <h4>All Events</h4>
                {/*<IonRow>*/}
                {/*    <IonCol size="3">Name</IonCol>*/}
                {/*    <IonCol size="3">Event Image</IonCol>*/}
                {/*    <IonCol size="3">View</IonCol>*/}
                {/*    <IonCol >Update</IonCol>*/}
                {/*</IonRow>*/}
                {/*@ts-ignore*/}
                {responseData && responseData.events.map((event, key)=>(
                    <IonRow key ={key} className={`${bgClassColors[key%2]}`}>
                        <IonCol size="5">{event["eventTitle"]}</IonCol>
                        <IonCol size="2">
                            <img src = {event["eventImageUrl"]} alt=""/>
                        </IonCol>
                        <IonCol size="2">
                            <Link to = {`/single-event/${event["id"]}`} className="custom-action-dark-green">
                                View
                            </Link>
                        </IonCol>
                        <IonCol size="3">
                            <Link to = {`/event-update/${event["id"]}`} className="custom-action-red">
                                Update
                            </Link>
                        </IonCol>
                    </IonRow>
                ))}

            </IonGrid>
        </AuthLayout>
    );
};

export default EventList;
