import {useContext,useState} from "react";
import axios from "axios";
import {baseUrl, headers} from "../global/Config";
import {MyContext} from "../statemanagement/ComponentState";

export const usePost = (url) => {

    const {setIsOpen, setResponseMessage, isSpinning, setIsSpinning} = useContext(MyContext);
    const [responseData, setResponseData] = useState(null);

    const postData = (formData) => {
        setIsSpinning(true);
        console.log(formData)
        axios.post(`${baseUrl}/${url}`, formData, headers)
            .then(res => {
                console.log(res);
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Successful !", res.data]);
                setResponseData(res.data);
            })
            .catch(err => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Error !", err.response.data.message]);
                console.log(err);
            });
    }
    return { isSpinning,responseData, postData};

}