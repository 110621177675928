import React from 'react';
import {IonIcon, IonLabel, IonTabBar, IonTabButton} from "@ionic/react";
import {
    apertureSharp,
    cartSharp,
    heartCircleSharp,
    heartOutline,
    heartSharp,
    home,
    person, settingsSharp,
    ticketSharp
} from "ionicons/icons";


function FooterTabs() {
    const user = JSON.parse(localStorage.getItem("user"));
    return (
        <IonTabBar slot="bottom" color="danger">
            <IonTabButton tab="home" href="/home">
                <IonIcon aria-hidden="true" icon={home} />
                <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton tab="favourite" href="/favourite">
                <IonIcon aria-hidden="true" icon={heartSharp} />
                <IonLabel>Favourite</IonLabel>
            </IonTabButton>
            <IonTabButton tab="ticket" href="/my-ticket">
                <IonIcon aria-hidden="true" icon={ticketSharp} />
                <IonLabel>Ticket</IonLabel>
            </IonTabButton>
            <IonTabButton tab="profile" href="/my-profile">
                <IonIcon aria-hidden="true" icon={person} />
                <IonLabel>Profile</IonLabel>
            </IonTabButton>
            {user && user.userType === "STAFF" &&
                <IonTabButton tab="settings" href="/settings">
                <IonIcon aria-hidden="true" icon={settingsSharp} />
                <IonLabel>Settings</IonLabel>
                </IonTabButton>
            }

        </IonTabBar>

    );
}


export default FooterTabs;