import React, {useEffect, useState} from 'react';
import GuestLayout from "./layout/GuestLayout";
import {useIonRouter} from "@ionic/react";
import GuestLayout3 from "./layout/GuestLayout3";
import GuestLayout2 from "./layout/GuestLayout2";

function StartPage() {
    const [showUpdateNotification, setShowUpdateNotification] = useState(false);

    const router = useIonRouter()

    const navigateToHomePage = () => {
       // router.push('/home'); // Navigate to a new page
         window.location.href = "/home";
    };

    let countDownCount = 5;
    const startCountdown = setInterval(()=> {
        countDownCount = countDownCount-1;
        document.querySelector(".countdown").innerHTML = countDownCount;

        if (countDownCount < 2){
            clearInterval(startCountdown);
            navigateToHomePage();
        }
    }, 1000);


    useEffect(() => {
        if ("serviceWorker" in navigator){
            navigator.serviceWorker.ready.then((registration) => {

                if(registration && registration.waiting) {
                    clearInterval(startCountdown);
                    setShowUpdateNotification(true);
                }
            });

        }
    },[])

    const handleUpdateClick = () => {
        if ("serviceWorker" in navigator){
            navigator.serviceWorker.ready.then((registration) => {
                if(registration && registration.waiting) {
                    registration.waiting.postMessage({type : 'SKIP_WAITING'} )
                    navigateToHomePage();
                    //window.location.reload();
                    //console.log(registration);
                }
            });
        }
    }

    return (
        <GuestLayout3>
                <>
            <div style={{height:"70vh",
                display:"flex",alignItems:"center",
                justifyContent:"center", flexDirection:"column"}}
            >
                {!showUpdateNotification &&
                <span className="text-white" style={{fontSize:"1.3em"}}>Please wait for about
                    <span className="text-orange"> <b><span className="countdown">{countDownCount}
                    </span>s</b>
                    </span>
                </span>
                }

                {showUpdateNotification &&
                    <div className='custom-update-notification'>
                        <span>A new version is available</span>
                        <button onClick={handleUpdateClick}>Update</button>
                    </div>
                }
            </div>
            </>

        </GuestLayout3>
    );
}

export default StartPage;