import axios from "axios";
import {baseUrl, headers} from "../global/Config";

export const logout = () => {

    localStorage.removeItem(("token"));
    localStorage.removeItem("user");
    localStorage.removeItem("tokenType");
    localStorage.removeItem("tokenExpirationTime");



    window.location.href = "/login";

    // axios.get(`${baseUrl}/api/logout`, headers)
    //     .then(res => {
    //
    //         console.log(res.data);
    //         localStorage.removeItem(("token"));
    //         localStorage.removeItem("user");
    //         localStorage.removeItem("tokenType");
    //         localStorage.removeItem("tokenExpirationTime");
    //
    //         window.location.href = "/";
    //
    //     })
    //     .catch(err => {
    //         console.log(err);
    //     })
}