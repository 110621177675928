import React from 'react';
import {useParams} from "react-router";
import FilteredEvent from "./FilteredEvent";
import {useFetch} from "../../hooks/useFetch";
import GuestLayout from "../layout/GuestLayout";

function EventBySearch() {
    const {searchParam} = useParams();

    const [responseData] = useFetch(`api/events/${searchParam}`);


    return (
        <GuestLayout>
            <h4 className={`custom-margin text-white`} style={{marginBottom:"5px"}}>
                Events related to <span className="text-orange"> "{searchParam}"</span>
            </h4>
            {
                responseData && <FilteredEvent events = {responseData.events} />
            }
        </GuestLayout>
    );
}


export default EventBySearch;