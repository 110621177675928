import React, {useContext, useEffect, useState} from 'react';
import GuestLayout from "./layout/GuestLayout";
import {arrowForward} from 'ionicons/icons';

import {getAllEventCategories} from "../helpers/EventCategoryHelper";
import {
    IonCard,
    IonCardContent,
    IonIcon,
} from "@ionic/react";
import {Link} from "react-router-dom";
import {MyContext} from "../statemanagement/ComponentState";

import {location} from "ionicons/icons";
import axios from "axios";
import {baseUrl} from "../global/Config";
import LoadingAnimation from "../components/LoadingAnimation";


function Home() {

    const [eventCategories, setEventCategories] = useState(null);
    const {isSpinning, setIsSpinning} = useContext(MyContext);
    const [featuredEvents, setFeaturedEvents] = useState(null);


   // const [event, isLoading] = useFetch(`api/events/featured`);

    useEffect(() => {
        getAllEventCategories(setEventCategories, setIsSpinning);
        axios.get(`${baseUrl}/api/featured-event`)
            .then(res => {
                console.log(res);
                setFeaturedEvents(res.data);
            })
            .catch(err => {

            })
    },[])

    return (
        <>
            <GuestLayout>

                    {isSpinning && <LoadingAnimation/>}

                    <br/>
                    <h4 className="ion-float-start text-white">Categories</h4>
                    <div className="custom-featured-content-parent">
                        {eventCategories && eventCategories.map((eventCategory, key) => (
                            // featuredEvent.events.map((eachEvent, key) => (
                            <div className="custom-featured-content-child">
                                <Link to={`/even-by-category/${eventCategory['id']}/${eventCategory["eventCategoryName"]}`}>
                                    <div style={{display:"flex"
                                        ,flexDirection:"column"}}>
                                        <img src={eventCategory["eventCategoryImage"]}
                                             className="custom-image-circle"
                                        />

                                        <div
                                            className="text-white ion-text-uppercase
                                custom-featured-event-caption">
                                            {eventCategory["eventCategoryName"]}
                                        </div>
                                    </div>

                                </Link>
                            </div>
                        ))}
                    </div>
                    <br/>
                    {eventCategories && eventCategories.map((eventCategory, key) => (
                        <div key = {key}>
                            {eventCategory["events"].length>0 &&

                                <div style={{padding:"5px 1px 5px 10px"}}>
                                    {key === 1 &&
                                        <h3 className="text-orange">Events</h3>
                                    }
                                    <Link  to={`/even-by-category/${eventCategory['id']}/${eventCategory["eventCategoryName"]}`}
                                           key = {key}>
                                        <div className="custom-item-align">
                                            <h4 className="ion-float-start" style={{marginTop:"5px"}}> {eventCategory["eventCategoryName"]}</h4>

                                            <IonIcon aria-hidden="true"
                                                     icon={arrowForward} size="small"
                                                     className="ion-float-end"/>
                                        </div>
                                    </Link>
                                </div>
                            }

                            <div className="custom-slide-parent">
                                {eventCategory["events"].length>0 && eventCategory["events"].map((uvibeEvent, eventKey) => (
                                    <div className="custom-slide" key ={eventKey}>
                                        <IonCard routerLink ={`/single-event/${uvibeEvent["id"]}`} className="custom-ion-card-style">
                                            <img alt="Silhouette of mountains" src={uvibeEvent["eventImageUrl"]}  className="custom-ion-card-img"/>
                                            <IonCardContent className="custom-ion-content-style custom-content-text-on-home">
                                                <div className="custom-text custom-margin-bottom">
                                                    <h6>{uvibeEvent["eventTitle"]}</h6>
                                                </div>
                                                <div className="custom-text custom-margin-bottom ">
                                               <span> <IonIcon aria-hidden="true"
                                                               icon={location} size="small" className="text-orange"
                                               ></IonIcon>{uvibeEvent["city"]}</span>
                                                </div>
                                                {/*<div className="custom-text custom-margin-bottom">*/}
                                                {/*    <span>From {uvibeEvent["currencySymbol"]}{uvibeEvent["price"]} / participant</span>*/}
                                                {/*</div>*/}

                                                {uvibeEvent.entryMode != "FREE" &&
                                                    <div className="custom-margin-bottom">
                                                        <span>From {uvibeEvent["currencySymbol"]}{uvibeEvent["price"]} / participant</span>
                                                    </div>
                                                }

                                                {uvibeEvent.entryMode == "FREE" &&
                                                    <div className="custom-margin-bottom">
                                                        <span>Free entry</span>
                                                    </div>
                                                }

                                                {/*{limitCharacters(hideParagraphTag(uvibeEvent["eventDescription"]))}*/}
                                            </IonCardContent>
                                        </IonCard>
                                    </div>
                                ))}
                            </div>

                        </div>
                    ))}
            </GuestLayout>
        </>
    );
}
export default Home;