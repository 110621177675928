import React, {useContext, useEffect, useState} from 'react';
import {
    IonInput,
    IonButton,
    IonItem,
    IonSelectOption,
    IonSelect,
    IonLabel, IonIcon
} from '@ionic/react';

import {baseUrl, headers} from "../../global/Config";
import axios from "axios";
import Spinner from "../../components/Spinner";
import ResponseMessage from "../../components/ResponseMessage";
import {MyContext} from "../../statemanagement/ComponentState";
import {EventRequestData} from "../../data/request/EventRequestData";
import AuthLayout from "../layout/AuthLayout";
import {getGroupedData} from "../../helpers/EventGeneralDataHelper";
import {openCity} from "../../helpers/NewCityHelper";
import "../../assets/css/custom.css";
import {appSettings} from "../../global/AppSettings";
import {addOutline, removeOutline} from "ionicons/icons";
import {handleInputChange} from "../../utility/InputChangeHandler";
import {useHistory} from "react-router";
//import * as contentArray from "ionicons/icons";

const NewEvent = () => {
    const [data, setData] = useState(EventRequestData);
    const [textAreaData, setTextAreaData] = useState(null);
    const [isSpinning, setIsSpinning] = useState(false);
    const {setIsOpen, setResponseMessage} = useContext(MyContext);
    const [isCustomCity, setIsCustomCity] = useState(false);

    const [variablePriceCount, setVariablePriceCount] = useState(0);
    const [variablePriceArray, setVariablePriceArray] = useState([]);
    const [variablePriceCaptionArray, setVariablePriceCaptionArray] = useState([]);
    const[isPaidEvent, setIsPaidEvent] = useState(false);

    // @ts-ignore
    const handleChange = (e) => {
        // @ts-ignore
        handleInputChange (e, data, setData, textAreaData ,setTextAreaData)
        openCity(e , setIsCustomCity );

        //PRICE TOGGLING
        if(e.target.name == "entryMode"){
            if(e.target.value == "TICKET"){
                setIsPaidEvent(true);
            }
            else {
                setIsPaidEvent(false);
            }
        }
    };

    const handleIndexedInputChange = (e, index) =>{
        const { name, value } = e.target;

        if(name == "variablePrices"){
            const newVariablePrices = [...variablePriceArray];
            newVariablePrices[index] = value;
            console.log(newVariablePrices)
            setVariablePriceArray(newVariablePrices);

            setData({ ...data, [name]: newVariablePrices });

        }else{
            const newVariablePriceCaptions = [...variablePriceCaptionArray];
            newVariablePriceCaptions[index] = value;
            //alert(value);
            console.log(newVariablePriceCaptions);
            setData({ ...data, [name]: newVariablePriceCaptions });
            setVariablePriceCaptionArray(newVariablePriceCaptions);

        }

    }

    const [cities, setCities] = useState(null);
    const [countries, setCountries] = useState(null);
    const [entryModes, setEntryModes] = useState(null);
    const [eventCategories, setEventCategories] = useState(null);
    const [contentArray, setContentArray] = useState(null);

    useEffect(()=> {
        getGroupedData(setCities,setCountries,setEntryModes,setEventCategories);
    }, []);

    // @ts-ignore
    const handleFileChange = (e) => {
        const name = e.target.name;
        const value = e.target.files;
        setData({ ...data, [name]: value[0] });
    };

    const history = useHistory();

    // @ts-ignore
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        addEvent();
    }

    // @ts-ignore
    const addEvent = () => {

        setIsSpinning(true);
        // @ts-ignore
        console.log(data)
        axios.post(`${baseUrl}/api/event`, data, headers
        )
            .then(res => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Successful !", res.data]);

                ///window.location.href = "/dashboard";

            })
            .catch(err => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Error !", err.response.data.message]);
                console.log(err);
            });
    }

    const decrementTicket = () => {
        if (variablePriceCount > 0) {
            setVariablePriceCount(variablePriceCount - 1);
        }
    }
    const incrementTicket = () => {
        setVariablePriceCount(variablePriceCount + 1);
    }


    useEffect(() => {
        const content = Array(variablePriceCount).fill('content'); // Create an array with 'content' based on num of variable price count
        setContentArray(content);
    },[variablePriceCount])


    // @ts-ignore
    // @ts-ignore
    return (
        <AuthLayout>
            <form onSubmit={handleSubmit}>
                <h4>New Event</h4>
                <div>
                    <select aria-label="eventCategory *"
                               name ="eventCategoryId"
                               placeholder="Event Category"
                               onChange = {handleChange}
                            required
                    >
                        {/*@ts-ignore*/}
                        <option value="">Select Category</option>
                        {eventCategories && eventCategories.map((eventCategory, key)=>(
                            <option value= {eventCategory["id"]} key ={key}>{eventCategory["eventCategoryName"]}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <IonInput
                        label="Title *"
                        labelPlacement="floating"
                        type="text"
                        name = "eventTitle"
                        onIonChange = {handleChange}
                        required>
                    </IonInput>
                </div>

                <div>
                    <textarea
                        aria-label="Description"
                        name="eventDescription"
                        onChange = {handleChange}
                        placeholder ="Event description"
                        rows = {appSettings.textAreaHeight}
                        required
                    >
                    </textarea>
                    {/*<IonInput*/}
                    {/*    label="Description"*/}
                    {/*    labelPlacement="floating"*/}
                    {/*    type="text"*/}
                    {/*    name="eventDescription"*/}
                    {/*    onIonChange = {handleChange}*/}
                    {/*    required>*/}
                    {/*</IonInput>*/}
                </div>

                <div>
                    <IonInput
                        label="Venue *"
                        labelPlacement="floating"
                        type="text"
                        name={"eventVenue"}
                        onIonChange = {handleChange}
                        required
                    ></IonInput>
                </div>

                <div className="custom-item-align custom-special-input">
                    <label><span>Start Date</span><br/>
                        <input
                            label="Start Date"
                            labelPlacement="stacked"
                            type="date"
                            name="eventDateStart"
                            onChange = {handleChange}

                        >
                        </input>
                    </label>

                    <label className="custom-text-right"><span>End Date</span><br/>
                        <input
                            label="End Date"
                            labelPlacement="stacked"
                            type="date"
                            name="eventDateEnd"
                            onChange = {handleChange}
                        >
                        </input>
                    </label>

                </div>

                <div className="custom-item-align custom-special-input">
                    <label>
                        <span>Start Time</span><br/>
                        <input label="Start Time"
                               labelPlacement="stacked"
                               placeholder="Enter text"
                               type = "time"
                               name = "eventTimeStart"
                               onChange = {handleChange}
                        >

                        </input>
                    </label>

                    <label className="custom-text-right">
                        <span className="custom-text-right">End Time</span><br/>
                        <input label="End Time"
                               labelPlacement="stacked"
                               placeholder="Enter text"
                               type = "time"
                               name = "eventTimeEnd"
                               onChange = {handleChange}
                        >

                        </input>
                    </label>

                </div>

                <div>
                    <IonInput
                        label="Event Capacity"
                        labelPlacement="floating"
                        type="number"
                        name="eventVenueCapacity"
                        onIonChange = {handleChange}
                    ></IonInput>
                </div>

                <div>
                    <select aria-label="country *"
                               name ="country"
                               placeholder="Country"
                               onChange = {handleChange}
                    >
                        <option value="">Select Country</option>
                        {countries && countries.map((country, key) => (
                            <option value= {country["Country"]} key ={key}>{country["Country"]}</option>
                        ))}

                    </select>
                </div>

                <div>
                    <select aria-label="city *"
                               name ="cityName"
                               placeholder="City"
                               onChange = {handleChange}
                    >
                        {/*@ts-ignore*/}
                        <option value="">Select City</option>
                        {cities && cities.map((city, key) => (
                            <option
                                value= {city["cityName"]}
                                key ={key}>{city["cityName"]}
                            </option>
                        ))}

                        <option value= "Others">
                            Others
                        </option>

                    </select>
                </div>

                {isCustomCity &&
                <>
                    <div>
                        <IonInput
                            label="City"
                            labelPlacement="floating"
                            type="text"
                            name="cityName"
                            onIonChange = {handleChange}
                            required
                        ></IonInput>
                    </div>

                    <div className="custom-special-input">
                        <label>
                            <span>City Image</span>
                            <input
                                type="file"
                                name="cityImageUrl"
                                onChange={handleFileChange}
                            />
                        </label>

                    </div>
                </>}

                <div>
                    <IonInput
                        label="Latitude"
                        labelPlacement="floating"
                        type="text"
                        name="latitude"
                        onIonChange = {handleChange}
                    ></IonInput>
                </div>

                <div>
                    <IonInput
                        label="Longitude"
                        labelPlacement="floating"
                        type="text"
                        name="longitude"
                        onIonChange = {handleChange}
                    ></IonInput>
                </div>

                <div>
                    <select aria-label="entryMode *"
                            name = "entryMode"
                            placeholder = "Entry Mode"
                            onChange = {handleChange}
                    >
                        <option value="">Select Entry Mode</option>
                        {entryModes && entryModes.map((entryMode, key) => (
                            <option value= {entryMode} key ={key}>{entryMode}</option>
                        ))}
                    </select>
                </div>

                <div>
                    <IonInput
                        label="Max Ticket"
                        labelPlacement="floating"
                        type="number"
                        name="maxTicket"
                        onIonChange = {handleChange}
                    ></IonInput>
                </div>

                {isPaidEvent &&
                    <>
                    {variablePriceCount === 0 &&
                    <div>
                        <IonInput
                            label="Price e.g $20"
                            labelPlacement="floating"
                            type="text"
                            name="price"
                            onIonInput = {handleChange}
                        ></IonInput>
                    </div>
                    }

                        <div className="custom-item-align" style={{marginTop:"10px"}}>
                            <div className="custom-full-width"><span>Variable Prices</span></div>
                            <div onClick={decrementTicket} className="dark-tint-bg round-circle inc-dec-button text-orange">
                                <IonIcon aria-hidden="true" icon={removeOutline} size="large"/>
                            </div>
                            <p style={{fontWeight:"bold",fontSize:"1.5em"}}>{variablePriceCount}</p>
                            <div onClick={incrementTicket} className="dark-shade-bg round-circle inc-dec-button text-orange">
                                <IonIcon aria-hidden="true" icon={addOutline} size="large"/>
                            </div>
                        </div>
                        {variablePriceCount > 0 && contentArray && contentArray.map((content, index) => (
                            <div className="custom-item-align">
                                <div className="custom-full-width">
                                    <IonInput
                                        label="Caption e.g VIP"
                                        labelPlacement="floating"
                                        type="text"
                                        name="variablePriceCaptions"
                                        onIonInput = {(e) => handleIndexedInputChange(e,index)}
                                        className=""
                                    ></IonInput>
                                </div>
                                <div className="custom-full-width">
                                    <IonInput
                                        label="Price e.g $20"
                                        labelPlacement="floating"
                                        type="number"
                                        name="variablePrices"
                                        onIonInput = {(e) => handleIndexedInputChange(e,index)}
                                        className=""
                                    ></IonInput>
                                </div>
                            </div>
                        ))
                        }
                    </>
                }

                <div className="custom-special-input">
                    <label className="text-white">
                        <span>Venue &nbsp;</span>
                        <input
                            type="file"
                            name="eventVenueImageUrl"
                            onChange={handleFileChange}
                        />
                    </label>
                </div>

                <div className="custom-special-input">
                    <label className="text-white">
                        <span>Event &nbsp;</span>
                        <input
                            type="file"
                            name="eventImageUrl"
                            onChange={handleFileChange}
                        />
                    </label>
                </div>

                <button shape="round"
                           color="danger"
                           expand="block"
                           type="submit"
                           className="ion-margin-top"
                >
                    Add Event
                    {isSpinning && <Spinner/>}
                </button>
            </form>
            <ResponseMessage/>
        </AuthLayout>
    );
};

export default NewEvent;
