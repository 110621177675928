import {makeParagraphText} from "./ParagraphMaker";

export const handleInputChange = (e, data, setData, textAreaData ,setTextAreaData) => {
    if(e.target.type === "file"){
        const name = e.target.name;
        const value = e.target.files;
        setData({ ...data, [name]: value[0] });
    }
    else{
        const { name, value } = e.target;
        setData({ ...data, [name]: value });

        if(e.target.type === "textarea"){
            const value = makeParagraphText(e.target.value);
            setTextAreaData({ ...textAreaData, [name]: value });
        }
    }


};