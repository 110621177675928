import React from 'react';
import {IonGrid, IonRow, IonCol, IonButton} from '@ionic/react';
import AuthLayout from "../layout/AuthLayout";
import {Link} from "react-router-dom";
import {useFetch} from "../../hooks/useFetch";
import {bgClassColors} from "../../global/Config";
import LoadingAnimation from "../../components/LoadingAnimation";

const UserRecord = () => {

    const [responseData, isSpinning] = useFetch(`api/user`);

    // const userDetails = (user) => {
    //     localStorage.setItem()
    // }

    return (
        <AuthLayout>
            {isSpinning && <LoadingAnimation/>}
            <IonGrid className = "record">
                <h4>All Users</h4>
                <IonRow>
                    <IonCol size="6">Name</IonCol>
                    <IonCol size="3">User Type</IonCol>
                    <IonCol>Action</IonCol>
                </IonRow>
                {/*@ts-ignore*/}
                {responseData && responseData.users.map((user, key)=>(
                    <IonRow key ={key} className={bgClassColors[key%2]}>
                        <IonCol size="6">{user["fullName"]}</IonCol>
                        <IonCol size="3">{user["userType"]}</IonCol>
                        <IonCol>
                            <Link to={`/user/${user["id"]}`}>
                                <div className="custom-action-red">View</div>
                            </Link>
                        </IonCol>
                        {/*<IonCol>*/}
                        {/*    <Link to={`/user/update/${user["id"]}`}>*/}
                        {/*        <div className="custom-action-red">Update</div>*/}
                        {/*    </Link>*/}
                        {/*</IonCol>*/}
                    </IonRow>
                ))}

            </IonGrid>
        </AuthLayout>
    );
};

export default UserRecord;
