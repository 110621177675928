import {Route } from 'react-router-dom';
import {
  IonApp, IonRedirect,
  IonRouterOutlet,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import React from "react";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import {MyContextProvider} from "./statemanagement/ComponentState";
import Dashboard from "./pages/Dashboard";
import NewEvent from "./pages/event/NewEvent";
import NewEventCategory from "./pages/event/NewEventCategory";
import EventList from "./pages/event/EventList";
import NewCity from "./pages/city/NewCity";
import CityList from "./pages/city/CityList";
import EventCategoryList from "./pages/event/EventCategoryList";
import SingleEvent from "./pages/event/SingleEvent";
import UpdateEvent from "./pages/event/UpdateEvent";
import UpdateEventCategory from "./pages/event/UpdateEventCategory";
import UpdateCity from "./pages/city/UpdateCity";
import EventByCategory from "./pages/filteredevents/EventByCategory";
import EventByCity from "./pages/filteredevents/EventByCity";
import EventBySearch from "./pages/filteredevents/EventBySearch";
import AboutUs from "./pages/footerpages/AboutUs";
import Contact from "./pages/footerpages/Contact";
import TicketOrder from "./pages/ticket/TicketOrder";
import Payment from "./pages/transaction/stripe/Payment";
import PaymentComplete from "./pages/transaction/stripe/PaymentComplete";
import UserProfile from "./pages/user/UserProfile";
import MyTicket from "./pages/ticket/MyTicket";
import EventFilterExtra from "./extra/EventFilterExtra";
import UserRecord from "./pages/user/UserRecord";
import SingleUser from "./pages/user/SingleUser";
import TicketList from "./pages/ticket/TicketList";
import SingleTicket from "./pages/ticket/SingleTicket";
import FavouriteEvent from "./pages/event/FavouriteEvent";
import StartPage from "./pages/StartPage";
import UpdateUser from "./pages/user/UpdateUser";
import Setting from "./pages/company/Setting";
import AppUrlListener from "./AppUrlListener";
import EmailVerification from "./pages/auth/EmailVerification";


setupIonicReact();

const App = () => (
    <MyContextProvider>
      <IonApp className="custom-container" style={{backgroundColor:"002e31"}}>
        <IonReactRouter>
          <AppUrlListener></AppUrlListener>
            <IonRouterOutlet>
              <Route exact path="/">
                <StartPage />
              </Route>
              <Route exact path="/home">
                <Home />
              </Route>
              <Route exact path="/aboutus">
                <AboutUs />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route exact path="/dashboard">
                <Dashboard />
              </Route>
              <Route exact path="/new-event">
                <NewEvent />
              </Route>
              <Route exact path="/events">
                <EventList />
              </Route>
              <Route exact path="/single-event/:eventId">
                <SingleEvent />
              </Route>
              <Route exact path="/event-update/:eventId">
                <UpdateEvent />
              </Route>

              <Route exact path="/new-event-category">
                <NewEventCategory />
              </Route>
              <Route exact path="/event-categories">
                <EventCategoryList />
              </Route>
              <Route exact path="/event-category-update/:eventCategoryId">
                <UpdateEventCategory />
              </Route>

              <Route exact path="/new-city">
                <NewCity />
              </Route>
              <Route exact path="/city-update/:cityId">
                <UpdateCity />
              </Route>
              <Route exact path="/cities">
                <CityList />
              </Route>

              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/register">
                <Register />
              </Route>
              <Route exact path="/email-verification">
                <EmailVerification />
              </Route>

              <Route exact path="/even-by-category/:eventCategoryId/:eventCategoryName">
                <EventByCategory />
              </Route>

              <Route exact path="/even-by-city/:cityName">
                <EventByCity />
              </Route>

              <Route exact path="/even-by-search/:searchParam">
                <EventBySearch />
              </Route>

              <Route exact path="/users">
                <UserRecord />
              </Route>
              <Route exact path="/user/update/:userId">
                <UpdateUser />
              </Route>
              <Route exact path="/my-profile">
                <UserProfile />
              </Route>
              <Route exact path="/user/:userId">
                <SingleUser />
              </Route>

              <Route exact path="/checkout">
                <TicketOrder />
              </Route>

              <Route exact path="/payment">
                <Payment />
              </Route>

              <Route exact path="/complete">
                <PaymentComplete />
              </Route>
              <Route exact path="/event-filter">
                <EventFilterExtra />
              </Route>

              <Route exact path="/tickets">
                <TicketList />
              </Route>
              <Route exact path="/ticket/:ticketId">
                <SingleTicket />
              </Route>
              <Route exact path="/my-ticket">
                <MyTicket />
              </Route>
              <Route exact path="/favourite">
                <FavouriteEvent />
              </Route>

              <Route exact path="/settings">
                <Setting />
              </Route>
            </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </MyContextProvider>
);

export default App;

