import React, {useContext, useState} from 'react';
import {
    IonInput,
    IonButton,
    IonItem,
    IonLabel
} from '@ionic/react';
import {baseUrl, headers} from "../../global/Config";
import axios from "axios";
import Spinner from "../../components/Spinner";
import ResponseMessage from "../../components/ResponseMessage";
import {MyContext} from "../../statemanagement/ComponentState";
import AuthLayout from "../layout/AuthLayout";
//import {CityRequestData} from "../../data/request/CityRequestData";
import {useParams} from "react-router";
import {useFetch} from "../../hooks/useFetch";

const UpdateCity = () => {
    //const [data, setData] = useState(CityRequestData);
    const [isSpinning, setIsSpinning] = useState(false);
    const {setIsOpen, setResponseMessage} = useContext(MyContext);
    const {cityId} = useParams();

    // useEffect(() => {
    //     getSingleCity();
    // },[])

    const [data, setData,setResponseData] = useFetch(`api/city/${cityId}`);

    // // @ts-ignore
    // const getSingleCity = () => {
    //     // @ts-ignore
    //     axios.get(`${baseUrl}/api/city/${cityId}`, headers
    //     )
    //         .then(res => {
    //             setData(res.data);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         });
    // }

    // @ts-ignore
    const handleChange = (e) => {
        const { name, value } = e.target;
        setResponseData({ ...data, [name]: value });
    };

    // @ts-ignore
    const handleFileChange = (e) => {
        const name = e.target.name;
        const value = e.target.files;
        setData({ ...data, [name]: value[0] });
    };

    // @ts-ignore
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        updateCity();
    }

    // @ts-ignore
    const updateCity = () => {

        setIsSpinning(true);
        // @ts-ignore
        axios.post(`${baseUrl}/api/city/${cityId}?_method=PUT`, data, headers)
            .then(res => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Successful !", res.data]);
            })
            .catch(err => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Error !", err.response.data.message]);
                console.log(err);
            });
    }

    return (
        <AuthLayout>
            {data &&
                <form onSubmit={handleSubmit}>
                    <h2>New City</h2>

                    <IonItem>
                        <IonInput
                            label="City Name *"
                            labelPlacement="floating"
                            type="text"
                            name = "cityName"
                            onIonChange = {handleChange}
                            value = {data.cityName}
                            required>
                        </IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel>
                            City Image
                        </IonLabel>
                        <input
                            type="file"
                            name="cityImageUrl"
                            onChange={handleFileChange}
                        />
                    </IonItem>

                    <button shape="round"
                               color="danger"
                               expand="block"
                               type="submit"
                               className="ion-margin-top"
                    >
                        Update City
                        {isSpinning && <Spinner/>}
                    </button>
                </form>
            }
            <ResponseMessage/>
        </AuthLayout>
    );
};

export default UpdateCity;
