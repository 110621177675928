import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent, IonToolbar, IonFooter, IonTitle, IonItem, IonIcon
} from '@ionic/react';
import {useHistory, useParams} from "react-router";
import {useFetch} from "../../hooks/useFetch";
import {MyContext} from "../../statemanagement/ComponentState";
import TicketFormModal from "../../modal/TicketFormModal";
import {hideParagraphTag} from "../../utility/ParagraphMaker";
import Spinner from "../../components/Spinner";
import GuestLayout3 from "../layout/GuestLayout3";
import FooterTabs from "../layout/FooterTabs";
import {baseUrl, headers, tokenFromStorage} from "../../global/Config";
import {TicketRequestData} from "../../data/request/TicketRequestData";
import {Link} from "react-router-dom";
import {
    addOutline,
    calendarSharp,
    locationSharp,
    navigateSharp,
    pricetag,
    removeOutline, ticketSharp,
    timerSharp,
    timeSharp
} from "ionicons/icons";
import GuestLayout from "../layout/GuestLayout";
import axios from "axios";
import LoadingAnimation from "../../components/LoadingAnimation";

const SingleEvent = () => {
    const [isLoading, setIsSpinning] = useState(false);
    const {setResponseMessage} = useContext(MyContext);
    const {eventId}  = useParams();

    //STORE THE EVENT IDS
    // Retrieve eventKeys from local storage
    let eventKeys = JSON.parse(localStorage.getItem("eventKeys")) || [];
// Check if eventId is not already in eventKeys
    if (!eventKeys.includes(eventId)) {
        // Add eventId to eventKeys
        eventKeys.push(eventId);

        // Store updated eventKeys in local storage
        localStorage.setItem("eventKeys", JSON.stringify(eventKeys));
    }



    const [event, isSpinning] = useFetch(`api/event/${eventId}`);
    const {isOpen, setIsOpen} = useContext(MyContext);

    //const [data, setData] = useState(TicketRequestData)

    const [ticketCount, setTicketCount] = useState(1);
    const [unitPrice, setUnitPrice] = useState(null);
    const [totalPrice, setTotalPrice] = useState(null);
    const [currencySymbol, setCurrencySymbol] = useState(null)

    const [activeVariablePriceRow, setActiveVariablePriceRow] = useState(0);
    const [variablePriceCaption, setVariablePriceCaption] = useState(null);

    const history = useHistory();

    const decrementTicket = () => {
        if (ticketCount > 1) {
            setTicketCount(ticketCount - 1);
        }
    }
    const incrementTicket = () => {
        console.log(event.numTicketRemaining);
        if(ticketCount < event.numTicketRemaining){
            setTicketCount(ticketCount + 1);
        }

    }

    useEffect(() => {

        event && setTotalPrice(event["price"]);
        event && setUnitPrice(event["price"]);
        event && setCurrencySymbol(event["currencySymbol"]);
        event && setVariablePriceCaption(event.variablePriceCaptions[0])
    },[event])


    useEffect(() => {
        unitPrice && setTotalPrice(unitPrice * ticketCount);
    },[ticketCount])


    const handleCheckoutClick = () => {

        const  data = {
            unitCharge : unitPrice,
            numberOfTickets : ticketCount,
            unitChargeAfterConversion : unitPrice,
            totalChargesAfterConversion:totalPrice,
            variablePriceCaption : variablePriceCaption,
            conversionRate : 1,
            totalCharges : totalPrice,
            currencyType : event["currencyShortName"],
            ticketType : event.entryMode,
            eventId : event["id"],
            organizerId : event["userId"],
            eventTitle : event["eventTitle"],
        }
        localStorage.setItem("ticketDetails", JSON.stringify(data));

        if(tokenFromStorage){
            window.location.href = "/checkout";
            // history.push(`/checkout`); // Navigate to the search page
            // setIsOpen(false);
        }
        else{
           // window.location.href = "/register";
             history.push(`/register`); // Navigate to the search page
            // setIsOpen(false);
        }
    }


    const handleVariablePrice = (variablePrice, variablePriceCaption, rowNumber) => {
        setUnitPrice(variablePrice);
        setTotalPrice(variablePrice * ticketCount)

        setActiveVariablePriceRow(rowNumber);
        setVariablePriceCaption(variablePriceCaption);
    }

    const toggleBackgroundColor = (rowNumber) => {
        return activeVariablePriceRow === rowNumber ? "custom-active-bg" : "custom-inactive-bg";
    }

    const SingleEventContent = <>
        {event &&
            <div style={{position:"relative"}}>
                <div style={{width:"100%",position:"sticky",top:"0"}}>
                    <img alt={event["eventTitle"]} src={event["eventImageUrl"]} style={{width:"100%"}}/>
                </div>

                <IonCard  style={{height:"auto"}}>
                    <div>
                        <IonCardHeader>
                            <IonCardTitle>{event["eventTitle"]}</IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                            <h2 className="text-tint">Description</h2>
                            <div dangerouslySetInnerHTML= {{ __html: event["eventDescription"]}}></div><br/>
                            <IonGrid style={{fontWeight:"bold",fontSize:"0.85em"}}>
                                <h2 className="text-tint">General Info</h2>
                                <IonRow>
                                    <IonCol size={5}><IonIcon aria-hidden="true" icon={locationSharp} size="medium"/> Venue</IonCol> <IonCol>{event.eventVenue}</IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size={5}><span><IonIcon aria-hidden="true" icon={calendarSharp} size="medium"/> Date</span></IonCol><IonCol>{event.eventDateStartAndEnd}</IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size={5}><IonIcon aria-hidden="true" icon={timeSharp} size="medium"/> Time</IonCol><IonCol>{event.eventTimeStartAndEnd}</IonCol>
                                </IonRow>
                                {(event.variablePrices.length === 0 && event.entryMode !="FREE") &&
                                    <IonRow>
                                        <IonCol size={5}><IonIcon aria-hidden="true" icon={pricetag} size="medium"/> Price</IonCol><IonCol>{event.currencySymbol}{event.price}</IonCol>
                                    </IonRow>
                                }
                                <IonRow>
                                    <IonCol size={5}><IonIcon aria-hidden="true" icon={navigateSharp} size="medium"/> Entry Mode</IonCol><IonCol>{event.entryMode}</IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size={5}><IonIcon aria-hidden="true" icon={ticketSharp} size="medium"/> Ticket Left</IonCol><IonCol>{event.numTicketRemaining}</IonCol>
                                </IonRow>
                            </IonGrid>

                            {event.variablePrices.length >0  &&
                                <IonGrid>
                                <IonRow>
                                    <b>Ticket + Fee</b>
                                </IonRow>
                                {event.variablePricesAndCaptions.map((variablePriceAndCaption, key) =>(
                                    <IonRow
                                        key = {key}
                                        onClick ={() => handleVariablePrice(variablePriceAndCaption.variablePrice, variablePriceAndCaption.variablePriceCaption, key)}
                                        className = {`${toggleBackgroundColor(key)} custom-pointer`}
                                    >
                                        <IonCol>{variablePriceAndCaption.variablePriceCaption}</IonCol>
                                        <IonCol>{event.currencySymbol}{variablePriceAndCaption.variablePrice}</IonCol>
                                    </IonRow>
                                ))}
                            </IonGrid>
                            }

                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          padding:"10px",
                                      }}
                                  >
                                      <div onClick={decrementTicket} className="dark-tint-bg round-circle inc-dec-button text-orange">
                                          <IonIcon aria-hidden="true" icon={removeOutline} size="large"/>
                                      </div>
                                      <p style={{fontSize:"1em"}}>{ticketCount} {ticketCount > 1 && "Tickets"}{ticketCount == 1 && "Ticket"}</p>
                                      <div onClick={incrementTicket} className="dark-shade-bg round-circle inc-dec-button text-orange">
                                          <IonIcon aria-hidden="true" icon={addOutline} size="large"/>
                                      </div>
                                  </div>

                                  <iframe style={{width : "100%", height:"250px"}}
                                          src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d191560.83606797215!2d${event["longitude"]}!3d${event["latitude"]}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a2ae52d441ab%3A0x899a0ba01aaace58!2sPark%20G%C3%BCell!5e0!3m2!1sen!2sng!4v1691746991785!5m2!1sen!2sng"
                                            style="border:0; width:100%; height:450px;" allowFullScreen="" loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade`} title="google  map">
                                  </iframe>

                            {/*<IonButton  shape="round" color="danger" expand="block" onClick={() => setIsOpen(true)}>Buy Ticket</IonButton>*/}

                        </IonCardContent>
                    </div>

                    <div style={{marginBottom:"20px", width:"90%", marginRight:"auto", marginLeft:"auto"}}>

                            {event && event.entryMode != "FREE" &&
                                <button to="#" onClick={handleCheckoutClick} className="text-white custom-single-event-button">
                                    <IonTitle style={{textAlign:"center"}}>
                                        GET IT NOW - {currencySymbol && currencySymbol}{totalPrice && totalPrice}
                                    </IonTitle>
                                </button>
                            }
                            {event && event.entryMode == "FREE" &&
                                <button to="#" onClick={handleCheckoutClick}
                                        className="text-white custom-single-event-button">
                                    <IonTitle style={{textAlign:"center"}}>
                                        Book Free {isLoading && <Spinner/>}
                                    </IonTitle>
                                </button>
                            }
                    </div>
                </IonCard>

            </div>

        }
        {isOpen &&
            <TicketFormModal event = {event}/>
        }
    </>

    return (
        <>
            <GuestLayout3>
                {SingleEventContent}
                {isSpinning && <LoadingAnimation/>}

                <div style={{height:"50px"}}></div>
            </GuestLayout3>

        </>

    );
};

export default SingleEvent;
