 // export const baseUrl = "http://127.0.0.1:8000";
 // export const frontendUrl = "http://localhost:3000";
export const baseUrl = "https://backend.uvibesapp.com";
export const frontendUrl = "https://uvibesapp.com";
//export const frontendUrl = window.location.protocol+ "//" +window.location.host;
export const tokenFromStorage = localStorage.getItem("token");
export const tokenExpirationTime = localStorage.getItem("tokenExpirationTime");

const headerConfig =  {
   'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin' :true,
    'Authorization' : `Bearer ${tokenFromStorage}`
};
export const headers = {
    headers : headerConfig
}

export const bgClassColors = ["custom-gray-bg","custom-white-bg"]