import React, {useEffect, useState} from "react";
import AuthLayout from "./layout/AuthLayout";
import {Link} from "react-router-dom";
import '../main.css';
import {
    accessibilitySharp, cartSharp,
    filterSharp,
    locateSharp,
    locationSharp, logOutSharp,
    peopleCircleSharp,
    peopleSharp, settingsSharp
} from "ionicons/icons";
import {IonIcon, IonToggle, useIonRouter} from "@ionic/react";
import {logout} from "../utility/Logout";
import GuestLayout from "./layout/GuestLayout";
import {tokenExpirationTime} from "../global/Config";
import GuestLayout2 from "./layout/GuestLayout2";
import {user} from "../data/response/AuthenticatedUserResponseData";

const Dashboard = () => {

    const [isEventOrganizer, setIsEventOrganizer] = useState(true);

    const handleEventOrganizerSwitching = () => {
        setIsEventOrganizer(!isEventOrganizer);
    }

    // const ionRouter = useIonRouter();
    // useEffect(() => {
    //     //AutoLogout();
    //
    //     const tokenExpiration = tokenExpirationTime ;
    //     const currentTime  = Math.floor(Date.now() / 1000);
    //
    //     // @ts-ignore
    //     if(currentTime > tokenExpiration){
    //        // logout();
    //          //window.location.href = "/";
    //         //ionRouter.push("/login");
    //     }
    // }, []); // Empty dependency array ensures it only runs on mount
    //
    // const handleLogout = () => {
    //     logout();
    //      window.location.href = "/";
    //     //ionRouter.push("/login");
    // }

    //const user = JSON.parse(localStorage.getItem("user"));

    return (

        <AuthLayout>

            {
                user &&

                <div className="general-style">
                    <h5><IonIcon aria-hidden="true" icon={accessibilitySharp} size="medium"/> Event</h5>

                    {(user.userType =="ORGANIZER" || user.userType =="STAFF") &&
                        <>
                            <Link to="/new-event"><button size="default" color="light">Create Event</button></Link>
                            <Link to="/events"><button size="default" color="light">View Event</button></Link>

                            {/*<h5><IonIcon aria-hidden="true" icon={cartSharp} size="medium"/> Tickets</h5>*/}
                            <Link to="/tickets"><button size="default" color="light">View Tickets</button></Link>

                        </>
                    }

                    {user.userType =="STAFF" &&
                        <div>
                            <div>
                                <Link to="/new-event-category"><button size="default" color="light">Create Category</button></Link>
                                <Link to="/event-categories"><button size="default" color="light">View Category</button></Link>
                            </div>

                            <div>
                                <h5><IonIcon aria-hidden="true" icon={locationSharp} size="medium"/> Location</h5>
                                <Link to="/new-city"><button size="default" color="light">Create City</button></Link>
                                <Link to="/cities"><button size="default" color="light">View City</button></Link>
                            </div>


                            <hr/>

                            <div style={{display:"block"}}><h5><IonIcon aria-hidden="true" icon={peopleSharp} size="medium"/> User</h5>
                            <Link to="/users"><button size="default" color="light">View Users</button></Link>
                            </div>

                        </div>
                    }

                </div>
            }

        </AuthLayout>

    );
}

export default Dashboard;