import React from 'react';
import GuestLayout from "../layout/GuestLayout";

function Contact() {
    return (
        <GuestLayout>

        </GuestLayout>
    );
}


export default Contact;