// import React from 'react';
// import {user} from "../../data/response/AuthenticatedUserResponseData";
// import HomeRoute from "../../utility/HomeRoute";
//
//
// function GuestLayout2({children}) {
//
//     // if(user){
//     //     window.location.href = "/dashboard";
//     //     return "";
//     // }
//
//
//     return (
//         <div style={{height:"90vh",display:"flex",
//             justifyContent:"center",
//             alignItems:"center",flexDirection:"column",padding:"0 15px 0 15px"}}>
//             <>
//                 <HomeRoute />
//             </>
//             {children}
//         </div>
//     );
// }
//
//
// export default GuestLayout2;

import React, {useContext, useEffect, useState} from 'react';
import Logo from '../../assets/images/logos/uvibes-logo-red.png';
import "../../assets/css/custom.css";
import {
    IonContent,
    IonHeader, IonIcon, IonItem,
    IonMenu,
    IonPage, IonSearchbar,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import EventFilter from "../../components/EventFilter";
import {useHistory} from "react-router";
import {useSearchBox} from "../../hooks/useSearchBox";
import Header from "./Header";
import {Link} from "react-router-dom";
import {logout} from "../../utility/Logout";
import {tokenFromStorage} from "../../global/Config";
import {MyContext} from "../../statemanagement/ComponentState";
import FooterTabs from "./FooterTabs";
import {filter, filterCircleSharp, filterOutline, filterSharp} from "ionicons/icons";
import HomeRoute from "../../utility/HomeRoute";
import EventFilterModal from "../../modal/EventFilterModal";
import LogoRed from "../../assets/images/logos/uvibes-logo-red.png";
function GuestLayout2({children}) {


    return (
        <>
            <IonPage id="main-content">
                <IonContent className="ion-padding">
                    <div style={{height:"90vh",display:"flex",
                        justifyContent:"center",
                        alignItems:"center",flexDirection:"column",padding:"0 15px 0 15px"}}>
                        <>
                            <HomeRoute />
                        </>
                        {children}
                    </div>
                </IonContent>

            </IonPage>
        </>
    );
}
export default GuestLayout2;