import {frontendUrl} from "../../global/Config";

export const UserRegistrationRequestData = {
    "fullName" : null,
    "email" : null,
    "password" : null,
    "confirmPassword" : null,
    "role" : "ROLE_USER",
    "userType" : "EVENT_ATTENDEE",
    "userImage" : null,
    "redirectUrl" : frontendUrl,
}