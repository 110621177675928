import React from 'react';
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon} from "@ionic/react";
import {hideParagraphTag, limitCharacters} from "../../utility/ParagraphMaker";
import {location} from "ionicons/icons";
function FilteredEvent(events) {
    console.log(events);
    return (
        <>
            {events && events["events"].map((event, key)=>(
                <IonCard key = {key} routerLink ={`/single-event/${event["id"]}`} className="custom-margin-10p">
                    <img alt="Silhouette of mountains" src={event["eventImageUrl"]} style={{width:"100%"}} />
                    {/*<IonCardHeader>*/}
                    {/*    <IonCardTitle>{event["eventTitle"]}</IonCardTitle>*/}
                    {/*</IonCardHeader>*/}

                    <IonCardContent className="custom-ion-content-style">

                        <div className="custom-margin-bottom">
                            <h5><b>{event["eventTitle"]}</b></h5>
                           <span> <IonIcon aria-hnpmnidden="true"
                                     icon={location} size="small"
                                           className="text-orange"
                            ></IonIcon>{event["cityName"]}
                           </span>
                        </div>
                        {event.entryMode != "FREE" &&
                            <div className="custom-margin-bottom">
                                <span>From {event["currencySymbol"]}{event["price"]} / participant</span>
                            </div>
                        }

                        {event.entryMode == "FREE" &&
                            <div className="custom-margin-bottom">
                                <span>Free entry</span>
                            </div>
                        }

                    </IonCardContent>
                </IonCard>

            ))}
        </>
    );
}

export default FilteredEvent;