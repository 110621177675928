import React from 'react';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon, IonItem,
    IonMenuButton,
    IonSearchbar,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import Logo from "../../assets/images/logos/uvibes-logo-red.png";
import {Link} from "react-router-dom";
import {
    arrowBack, calendarSharp,
    ellipseSharp,
    ellipsisHorizontalSharp, ellipsisVerticalSharp,
    filterSharp, giftSharp,
    locationSharp, navigateCircleSharp, openSharp,
    personCircle
} from "ionicons/icons";
import {useHistory} from "react-router";
import HomeRoute from "../../utility/HomeRoute";
import {user} from "../../data/response/AuthenticatedUserResponseData";

function Header() {
    const history = useHistory();
    //const currentRoute = history.location.pathname;

    return (
        <IonHeader style={{backgroundColor:"#002e31"}}>
            <div>
                    <IonButtons slot="start" style={{position:"absolute"}}>
                        <IonBackButton color="light" style={{color:"#fff"}}></IonBackButton>
                    </IonButtons>

                <div className="custom-menu-title"><HomeRoute />
                </div>
                {user &&
                    <IonButtons slot="end" style={{position:"absolute", top:"0", right:"0"}}>
                        <IonItem>
                            <Link to="/dashboard"><IonIcon className="text-white" aria-hidden="true" icon={calendarSharp} size="large"/></Link>
                        </IonItem>
                    </IonButtons>
                }

            </div>
        </IonHeader>

    );
}
export default Header;