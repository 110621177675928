import React from 'react';
import GuestLayout from "../layout/GuestLayout";

function AboutUs() {
    return (
        <GuestLayout>

        </GuestLayout>
    );
}


export default AboutUs;