import React, {useContext, useState} from 'react';
import {IonInput, IonButton, IonItem, IonTitle, useIonRouter} from '@ionic/react';
import {Link} from "react-router-dom";
import {UserLoginRequestData} from "../../data/request/UserLoginRequest";
import axios from "axios";
import {baseUrl, headers} from "../../global/Config";
import {MyContext} from "../../statemanagement/ComponentState";
import Spinner from "../../components/Spinner";
import ResponseMessage from "../../components/ResponseMessage";
//import {ticketDetails} from "../../storage/TicketDetails";
import GuestLayout2 from "../layout/GuestLayout2";
import Logo from "../../assets/images/logos/uvibes-logo-red.png";
import GuestLayout from "../layout/GuestLayout";
import GuestLayout3 from "../layout/GuestLayout3";

const Login = () => {
    const [isSpinning, setIsSpinning] = useState(false);
    const {isOpen, setIsOpen, setResponseMessage, setToken} = useContext(MyContext);
    const [data, setData] = useState(UserLoginRequestData);

    // @ts-ignore
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    // @ts-ignore
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        loginUser();
    }

    const ionRouter = useIonRouter();

    const loginUser = () => {
        setIsSpinning(true);
        console.log(data)
        axios.post(`${baseUrl}/api/login`, data, headers)
            .then(res => {
                setIsSpinning(false);
                const authUserData = res.data;
                localStorage.setItem("token", authUserData.access_token);
                localStorage.setItem("tokenExpirationTime", authUserData.expires_in);
                localStorage.setItem("tokenType", authUserData.token_type);
                localStorage.setItem("user", JSON.stringify(authUserData.user));
                setToken(authUserData.access_token);
                const ticketDetails = localStorage.getItem("ticketDetails");
                if(ticketDetails){
                    //history.push(`/checkout`); // Navigate to the search page
                    window.location.href="/checkout";
                    //ionRouter.push("/checkout");
                }
                else {
                    setIsOpen(true);
                    //setResponseMessage(["Successful !", "Login Successful"]);
                    window.location.href="/my-profile";
                    //ionRouter.push("/dashboard");
                }

                //history.push("/dashboard"); // Navigate to the search page
            })
            .catch(err => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Error !", err.response.data.message]);
                console.log(err);
            });
    }

    // @ts-ignore
    return (
        <GuestLayout2>
            <form onSubmit={handleSubmit}>
                <h2>Log In</h2>
                <div>
                    <IonInput
                        label="Email"
                        labelPlacement="floating"
                        type="email"
                        name="email"
                        onInput = {handleChange}>
                    </IonInput>
                </div>


                <div>
                    <IonInput
                        label="Password"
                        labelPlacement="floating"
                        type="password"
                        name="password"
                        onInput = {handleChange}
                    >
                    </IonInput>
                </div>

                <br/>

                <button shape="round" color="danger" expand="block" type="submit">Login {isSpinning && <Spinner/>}</button>
            </form>

            <div className="ion-text-center text-white" style={{marginTop:"15px"}}>Don't have an account?<Link to="/register" className="text-orange">&nbsp;Register</Link></div>

            {isOpen && <ResponseMessage/> }
        </GuestLayout2>
    );
};

export default Login;
