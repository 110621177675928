import {maxCharacter} from "../global/AppSettings";

export const makeParagraphText = (inputText) =>{
    const paragraphs = inputText.split('\n\n'); // Split by double line breaks to identify paragraphs
    return paragraphs.map(paragraph => `<p>${paragraph}</p>`).join('\n');
}

export const hideParagraphTag = (description) => {
    if(description){
        const contentWithoutParagraphClosingTags = description.replace(/<\/p>/g, '\n');
        return contentWithoutParagraphClosingTags.replace(/<\/?p>/g, '');
    }

    return ;

};

export const  limitCharacters = (inputString) => {
    if (inputString.length > maxCharacter) {
        return inputString.substring(0, maxCharacter)+ " ...";
    }
    return inputString + "...";
}

export const  limitCharacters2 = (inputString) => {
   let wordSplit = inputString.split(" ");
    return wordSplit[0]+" "+wordSplit[1];
}
