import React from 'react';
import GuestLayout from "../pages/layout/GuestLayout";
import EventFilter from "../components/EventFilter";

function EventFilterExtra() {
    return (
        <GuestLayout>
            <EventFilter />
        </GuestLayout>
    );
}


export default EventFilterExtra;