import React, {useEffect} from 'react';
import {
    IonButtons,
    IonContent,
    IonHeader, IonItem,
    IonMenu, IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar, useIonRouter
} from '@ionic/react';
import {AutoLogout} from "../../utility/TokenExpiration";
import {Link} from "react-router-dom";
import Header from "./Header";
import {logout} from "../../utility/Logout";
import FooterTabs from "./FooterTabs";
import {tokenExpirationTime} from "../../global/Config";
import HomeRoute from "../../utility/HomeRoute";
// @ts-ignore
function AuthLayout({children}) {
    //Call AutoLogout whenever the component is mounted

    const ionRouter = useIonRouter();
    useEffect(() => {
        //AutoLogout();

        const tokenExpiration = tokenExpirationTime ;
        const currentTime  = Math.floor(Date.now() / 1000);

        // @ts-ignore
        if(currentTime > tokenExpiration){
            logout();
            // window.location.href = "/login";
           // ionRouter.push("/login");
        }
    }, []); // Empty dependency array ensures it only runs on mount

    return (
        <>
            <IonMenu contentId="main-content">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle><HomeRoute /></IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonItem detail={true} routerLink="/new-event" >
                            Create Event
                    </IonItem>
                    <IonItem detail={true} routerLink="/events" >
                        View Event
                    </IonItem>
                    <IonItem detail={true} routerLink="/new-event-category" >
                        Create Event Category
                    </IonItem>
                    <IonItem detail={true} routerLink="/event-categories" >
                        View Event Category
                    </IonItem>
                    <IonItem detail={true} routerLink="/new-city" >
                        New City
                    </IonItem>
                    <IonItem detail={true} routerLink="/users" >
                        All Users
                    </IonItem>
                    <IonItem detail={true} routerLink="/my-profile" >
                        My Profile
                    </IonItem>
                    <IonItem >
                        {/*@ts-ignore*/}
                        <Link to = "#" onClick = {logout}>
                            Logout
                        </Link>
                    </IonItem>
                </IonContent>
            </IonMenu>
            <IonPage id="main-content">
                <Header />
                <IonContent className="ion-padding">{children}</IonContent>
                <FooterTabs/>
            </IonPage>
        </>
    );
}
export default AuthLayout;