import axios from "axios";
import {baseUrl, headers} from "../global/Config";

// @ts-ignore
export const getGroupedData = (setCities,setCountries,setEntryModes,setEventCategories) => {
    axios.get(`${baseUrl}/api/event-data`, headers
    )
        .then(res => {
            const data = res.data;
            setCities(data.cities.cities);
            setCountries(data.countries);
            setEntryModes(data.entryModes);
            setEventCategories(data.eventCategories.eventCategories);

        })
        .catch(err => {
            console.log(err);
        });
}