export const useSearchBox = (route) =>{
    const authRoutes = ["/login", "/register","/payment","/complete"];
    let  isSearchBox = false;

    if (!authRoutes.includes(route)){
        isSearchBox = true
    }
    else{
        isSearchBox = true
    }

    return isSearchBox;


}