import React from 'react';
import {useParams} from "react-router";
import FilteredEvent from "./FilteredEvent";
import {useFetch} from "../../hooks/useFetch";
import GuestLayout from "../layout/GuestLayout";

function EventByCity() {
    const {cityName} = useParams();

    const [responseData] = useFetch(`api/event/city/${cityName}`);

    return (
        <GuestLayout>
            <h3 className={`custom-margin`} style={{marginBottom:"5px"}}>
               Events in {cityName}
            </h3>
            {
                responseData && <FilteredEvent events = {responseData.events} />
            }
        </GuestLayout>
    );
}


export default EventByCity;