import React from 'react';
import {IonCol, IonGrid, IonRow} from "@ionic/react";
import {bgClassColors} from "../../global/Config";

function Invoice() {

    const data =JSON.parse(localStorage.getItem("ticketDetails"));

    return (
        <IonGrid className = "record-single">
            <IonRow className={bgClassColors[0]}>
                <IonCol size="5" >Event Title</IonCol><IonCol>{data.eventTitle}</IonCol>
            </IonRow>

            {data.variablePriceCaption &&
                <IonRow className={bgClassColors[1]}>
                    <IonCol>Ticket Variation</IonCol>
                    <IonCol>{data.variablePriceCaption}</IonCol>
                </IonRow>
            }

            <IonRow className={bgClassColors[1]}>
                <IonCol size="5" >Num Of Tickets</IonCol> <IonCol>{data.numberOfTickets}</IonCol>
            </IonRow>

            {data.ticketType !== "FREE" &&
                <>
                    <IonRow className={bgClassColors[0]}>
                        <IonCol size="5" >Unit Price</IonCol><IonCol>({data.currencyType}){data.unitChargeAfterConversion}</IonCol>
                    </IonRow>
                    <IonRow className={bgClassColors[0]}>
                        <IonCol size="5" >Total Price</IonCol><IonCol>({data.currencyType}){data.totalChargesAfterConversion}</IonCol>
                    </IonRow>
                </>
            }
        </IonGrid>
    );
}
export default Invoice;