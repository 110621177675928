import React from 'react';
import {IonSpinner } from '@ionic/react';

function Spinner() {
    return (
        <div style={{position:"absolute",right:"80px",top:"10px"}}>
            &nbsp; <IonSpinner name="circular" className="text-white"></IonSpinner>
        </div>
    );
}
export default Spinner;