import React, {useState} from 'react';
import {
    IonInput,
    IonButton,
    IonItem,
    IonLabel
} from '@ionic/react';
import Spinner from "../../components/Spinner";
import ResponseMessage from "../../components/ResponseMessage";
import AuthLayout from "../layout/AuthLayout";
import {useParams} from "react-router";
import {appSettings} from "../../global/AppSettings";
import {hideParagraphTag} from "../../utility/ParagraphMaker";
import {handleInputChange} from "../../utility/InputChangeHandler";
import {usePost} from "../../hooks/usePost";
import {useFetch} from "../../hooks/useFetch";

const UpdateEventCategory = () => {
    const [textAreaData, setTextAreaData] = useState(null);

    const {eventCategoryId}  = useParams();
    const [data,isLoading,setData] = useFetch(`api/event-category/${eventCategoryId}`);

    const handleChange = (e) => {
        handleInputChange (e, data, setData, textAreaData ,setTextAreaData)
    };

    const {isSpinning,postData} = usePost(`api/event-category/${eventCategoryId}?_method=PUT`);

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        const updatedData = {...data, ...textAreaData}
        postData(updatedData);
    }

    return (
        <AuthLayout>
            {data &&
                <form onSubmit={handleSubmit}>
                    <h4>Event Category Update</h4>

                    <IonItem>
                        <IonInput
                            label="Category Name *"
                            labelPlacement="floating"
                            type="text"
                            name = "eventCategoryName"
                            onIonChange = {handleChange}
                            value = {data.eventCategoryName}
                            required>
                        </IonInput>
                    </IonItem>

                    <IonItem>
                        <textarea
                            name="eventCategoryDescription"
                            rows = {appSettings.textAreaHeight}
                            required
                            onChange = {handleChange}
                            className="custom-textarea"
                            value={`${hideParagraphTag(data.eventCategoryDescription)}`}
                        >
                    </textarea>
                    </IonItem>

                    <IonItem>
                        <IonLabel>
                            Image
                        </IonLabel>
                        <input
                            type="file"
                            name="eventCategoryImage"
                            onChange={handleChange}
                        />
                    </IonItem>

                    <button shape="round"
                               color="danger"
                               expand="block"
                               type="submit"
                               className="ion-margin-top"
                    >
                        Update Event Category
                        {isSpinning && <Spinner/>}
                    </button>
                </form>
            }
            <ResponseMessage/>
        </AuthLayout>
    );
};

export default UpdateEventCategory;
