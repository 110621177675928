import React, {useEffect, useState} from 'react';
import {IonAvatar, IonCard, IonCardContent, IonLabel, IonToggle} from "@ionic/react";
import AuthLayout from "../layout/AuthLayout";
import {useParams} from "react-router";
import {useFetch} from "../../hooks/useFetch";
import Spinner from "../../components/Spinner";
import {AuthenticatedUserResponseData} from "../../data/response/AuthenticatedUserResponseData";
import {useToggle} from "../../hooks/UseToggle";

function SingleUser() {
    const {userId} = useParams();
    const [user, isSpinning] = useFetch(`api/user/${userId}`);
    const authUser = AuthenticatedUserResponseData

    const [isUserBlocked,setIsUserBlocked] = useState(0);
    useEffect(() => {

        user && setIsUserBlocked(user.isUserBlocked);

    },[user])

    const {isSpinning2,toggleData} = useToggle(`api/user/block/${userId}`);

    const handleChange = () => {
        const isBlocked = !isUserBlocked
        setIsUserBlocked(isBlocked);
        toggleData(isBlocked)
    }

    return (
        <AuthLayout>
            <IonCard>
                <IonAvatar className="ion-paddin">
                    <img alt="Silhouette of a person's head"
                         src="https://ionicframework.com/docs/img/demos/avatar.svg"
                         style={{padding:"5px"}}
                    />
                </IonAvatar>
                <IonCardContent color="danger" className="custom-single-user">
                    {user &&
                        <>
                            <div style={{fontSize:"1.2em"}} className="profile">
                                <div>
                                    <IonLabel>Full Name:</IonLabel> &nbsp;
                                    <IonLabel>{user.fullName}</IonLabel>
                                </div>
                                <div>
                                    <IonLabel>Email:</IonLabel> &nbsp;&nbsp;
                                    <IonLabel>{user.email}</IonLabel>
                                </div>
                                <div>
                                    <IonLabel>Account Type:</IonLabel> &nbsp;
                                    <IonLabel>{user.userType}</IonLabel>
                                </div>
                            </div>
                        </>
                    }

                </IonCardContent>
            </IonCard>

            {
                authUser.id != userId && user && authUser.userType == "STAFF" &&
                <div style={{padding:"10px"}}>
                    <div className="custom-margin-bottom">
                        <label className="toggle">
                            <input className="toggle-checkbox"
                                   type="checkbox" checked={isUserBlocked} onChange={handleChange}/>
                            <div className="toggle-switch"></div>
                            <span className="toggle-label text-white">Block</span>
                        </label>
                    </div>
            {/*<IonToggle color="danger" >Event Organizer</IonToggle>*/}
        </div>

            }


            {isSpinning && <Spinner/>}

        </AuthLayout>
    );
}

export default SingleUser;