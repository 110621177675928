import React, {useContext, useRef, useState} from 'react';
import {IonInput, IonButton, IonItem, useIonRouter} from '@ionic/react';
import {UserRegistrationRequestData} from "../../data/request/UserRegistrationRequestData";
import {baseUrl} from "../../global/Config";
import axios from "axios";
import Spinner from "../../components/Spinner";
import ResponseMessage from "../../components/ResponseMessage";
import {MyContext} from "../../statemanagement/ComponentState";
import {Link} from "react-router-dom";
import {ticketDetails} from "../../storage/TicketDetails";
import GuestLayout2 from "../layout/GuestLayout2";
import GuestLayout from "../layout/GuestLayout";
import {useHistory} from "react-router";

const Register = () => {
    const [data, setData] = useState(UserRegistrationRequestData);
    const [isSpinning, setIsSpinning] = useState(false);
    const {setIsOpen, setResponseMessage} = useContext(MyContext);

    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const [isPasswordMatched , setIsPasswordMatched] = useState(true);

    const history = useIonRouter();


    // @ts-ignore
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });

        if(e.target.name === "confirmPassword"){
            const isPasswordMatched = passwordRef.current.value === passwordConfirmRef.current.value;
            setIsPasswordMatched(isPasswordMatched);
        }
    };

    // @ts-ignore
    const handleSubmit = (e) => {
       e.preventDefault(); // Prevent the default form submission behavior
       registerUser();
    }

    // @ts-ignore
    const registerUser = () => {
        setIsSpinning(true);
       console.log(data)
            axios.post(`${baseUrl}/api/register`, data)
                .then(res => {
                    const  data = res.data;
                    setIsSpinning(false);
                    setIsOpen(true);

                    if(data.isMobileDevice){
                        window.location.href = "/email-verification";
                    }
                    else{
                        setResponseMessage(["Successful !", data.successMessage]);
                    }

                    //setResponseMessage(["Successful !", data.successMessage]);

                    //console.log(res);
                })
                .catch(err => {
                    setIsSpinning(false);
                    setIsOpen(true);
                    setResponseMessage(["Error !", err.response.data.message]);
                   console.log(err);
                });
    }

    // @ts-ignore
    // @ts-ignore
    return (
        <GuestLayout2>
            <form onSubmit={handleSubmit}>
                <h2>Registration</h2>

                <div>
                    <IonInput
                        label="Full Name"
                        labelPlacement="floating"
                        type="text"
                        name = "fullName"
                        onIonInput = {handleChange}>
                    </IonInput>
                </div>


                <div>
                    <IonInput
                        label="Email"
                        labelPlacement="floating"
                        type="email"
                        name={"email"}
                        onIonInput = {handleChange}>
                    </IonInput>
                </div>


                <div>
                    <IonInput
                        label="Password"
                        labelPlacement="floating"
                        type="password"
                        name={"password"}
                        onIonInput = {handleChange}
                        ref = {passwordRef}
                    ></IonInput>
                </div>

                <div>
                    <IonInput
                        label="Confirm Password"
                        labelPlacement="floating"
                        type="password"
                        name="confirmPassword"
                        onIonInput = {handleChange}
                        ref = {passwordConfirmRef}
                    >
                    </IonInput>
                </div>
                <div>
                    {
                        !isPasswordMatched &&
                        <span className="text-orange">Password not matched</span>
                    }
                </div>
                <br/>
                <button type="submit" disabled={!isPasswordMatched}>Register {isSpinning && <Spinner/>}</button>
            </form>
            <div className="ion-text-center text-white" style={{marginTop:"15px"}}>Already have an account ?<Link to="/login" className="text-orange">&nbsp;Login</Link></div>
       <ResponseMessage/>
        </GuestLayout2>
    );
};

export default Register;
