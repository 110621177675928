import React, {useContext, useEffect, useRef, useState} from 'react';
import AuthLayout from "../layout/AuthLayout";
import {IonAvatar, IonButton, IonInput, IonItem} from "@ionic/react";
import Spinner from "../../components/Spinner";
import {useParams} from "react-router";
import {useFetch} from "../../hooks/useFetch";
import {handleInputChange} from "../../utility/InputChangeHandler";
import {usePost} from "../../hooks/usePost";
import {Link} from "react-router-dom";
import axios from "axios";
import {baseUrl, headers} from "../../global/Config";
import {MyContext} from "../../statemanagement/ComponentState";
import ResponseMessage from "../../components/ResponseMessage";

function UpdateUser() {


    const {userId} = useParams();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const [isPasswordMatched , setIsPasswordMatched] = useState(true);
    const imageUploadInputRef = useRef(null);
    const [data, isLoading, setData] = useFetch(`api/user/${userId}`);
    const [userImageUrl, setUserImageUrl] = useState("https://ionicframework.com/docs/img/demos/avatar.svg")

    const {setIsOpen, setResponseMessage, isSpinning, setIsSpinning} = useContext(MyContext);
    const [responseData, setResponseData] = useState(null);

    useEffect(() => {
        if(data){
            if(data.userImage){
                setUserImageUrl(data.userImage);
            }
        }
    }, [data])

    //CALL POST CUSTOM HOOK
    //const {isSpinning,postData} = usePost(`api/user/${userId}?_method=PUT`);

    //INPUT CHANGE HANDLER
    const [textAreaData, setTextAreaData] = useState(null);
    const handleChange = (e) => {
        handleInputChange (e, data, setData, textAreaData ,setTextAreaData)

        if(e.target.name === "confirmPassword"){
            const isPasswordMatched = passwordRef.current.value === passwordConfirmRef.current.value;
            setIsPasswordMatched(isPasswordMatched);
        }

        if(e.target.type ==="file"){
            const file = e.target.files[0];

            if (file) {
                // You can perform additional validation and upload logic here
                // For this example, we'll just update the displayed image
                const reader = new FileReader();
                reader.onload = (e) => {
                    //setImageSrc(e.target.result);
                    //setData({...data, userImage : e.target.result})
                    setUserImageUrl(e.target.result)
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        setIsSpinning(true);
        axios.post(`${baseUrl}/api/user/${userId}?_method=PUT`, data, headers)
            .then(res => {
                console.log(res);
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Successful !", "Profile updated"]);
                setResponseData(res.data);
                localStorage.setItem("user", JSON.stringify(res.data));
            })
            .catch(err => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Error !", err.response.data.message]);
                console.log(err);
            });
    }

    return (
        <AuthLayout>
            {data &&
                <form onSubmit={handleSubmit}>
                    <h4>Update Profile</h4>
                    <IonItem>
                        {/* Display the current profile picture */}

                        <IonAvatar>
                            {userImageUrl &&
                                <img
                                    src={userImageUrl}
                                    alt="Profile Picture"
                                    className="profile-picture"
                                    onClick={() => imageUploadInputRef.current.click()}
                                />
                            }


                        </IonAvatar>


                        {/* Input for uploading a new profile picture */}
                        <input
                            type="file"
                            id="upload-button"
                            accept="image/*"
                            style={{ display: 'none' }}
                            ref = {imageUploadInputRef}
                            onChange={handleChange}
                            name ="userImage"
                        />
                    </IonItem>

                    <IonItem>
                        <IonInput
                            label="Full Name"
                            labelPlacement="floating"
                            type="text"
                            name = "fullName"
                            onIonInput = {handleChange}
                            value = {data.fullName}
                            required
                        >
                        </IonInput>
                    </IonItem>

                    <IonItem>
                        <IonInput
                            label="Email"
                            labelPlacement="floating"
                            type="email"
                            name={"email"}
                            onIonInput = {handleChange}
                            value = {data.email} disabled={true}
                        >
                        </IonInput>
                    </IonItem>

                    {/*<IonItem><Link><button className="custom-button-more-action"><span>Show Password</span></button></Link></IonItem>*/}
                    <div>
                        <IonItem>
                            <IonInput
                                label="New Password"
                                labelPlacement="floating"
                                type="password"
                                name={"password"}
                                onIonInput = {handleChange}
                                value={data.password}
                                ref = {passwordRef}
                                required
                            ></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonInput
                                label="Confirm Password"
                                labelPlacement="floating"
                                type="password"
                                name="confirmPassword"
                                onIonInput = {handleChange}
                                value={data.confirmPassword}
                                ref = {passwordConfirmRef}
                                required
                            >
                            </IonInput>

                        </IonItem>
                        {
                            !isPasswordMatched &&
                            <IonItem><span className="text-orange">Password not matched</span></IonItem>
                        }
                    </div>
                    <br/>
                    <button shape="round"  type="submit" disabled={!isPasswordMatched}>Update {isSpinning && <Spinner/>}</button>
                    <ResponseMessage/>
                </form>
            }
        </AuthLayout>
    );
}


export default UpdateUser;