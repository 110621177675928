import React from 'react';
import LogoWhite from "../assets/images/logos/uvibes-logo-white.png";
import LogoRed from "../assets/images/logos/uvibes-logo-red.png";
import {useHistory} from "react-router";
import {IonBackButton, IonButtons, IonIcon} from "@ionic/react";
import {arrowForward} from "ionicons/icons";

function HomeRoute() {
    let logo = LogoWhite;
    const history = useHistory();
    const blackListedRoute = ["/login", "/register"];
    const currentRoute = history.location.pathname;

    logo = LogoRed;
    if(blackListedRoute.includes(currentRoute)){
        logo = LogoRed;
    }

    const gotoHome = () => {
        //history.push("/");
        window.location.href="/home"
    }

    return (
        <>
            <img src={logo} height={48}
                 alt="Logo"
                 onClick={gotoHome} className="custom-pointer"/><br/>
        </>
    );
}


export default HomeRoute;