import React, {useContext, useEffect, useState} from 'react';
import Logo from '../../assets/images/logos/uvibes-logo-red.png';
import "../../assets/css/custom.css";
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader, IonIcon, IonItem,
    IonMenu,
    IonPage, IonSearchbar,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import EventFilter from "../../components/EventFilter";
import Header from "./Header";
import {Link} from "react-router-dom";
import {logout} from "../../utility/Logout";
import {tokenFromStorage} from "../../global/Config";
import FooterTabs from "./FooterTabs";
import {arrowForward, filter} from "ionicons/icons";
import HomeRoute from "../../utility/HomeRoute";
function GuestLayout3({children}) {

    return (
        <>
            {/*<IonIcon aria-hidden="true"*/}
            {/*         icon={arrowForward} size="small"*/}
            {/*         className="ion-float-end"/>*/}
            <IonMenu contentId="main-content">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle><HomeRoute /></IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <EventFilter />

                    {tokenFromStorage &&
                        <>
                            <IonItem >
                                <a href = "/dashboard">
                                    Dashboard
                                </a>
                            </IonItem>
                            <IonItem >
                                <Link to = "#" onClick = {logout}>
                                    Logout
                                </Link>
                            </IonItem>
                        </>
                    }
                </IonContent>
            </IonMenu>
            <IonPage id="main-content">
                <Header />
                <IonContent>
                    {children}
                </IonContent>
                <FooterTabs />
            </IonPage>
        </>
    );
}
export default GuestLayout3;