import React, {useEffect, useState} from 'react';
import {
    IonInput,
    IonButton,
    IonItem,
    IonSelectOption,
    IonSelect,
    IonLabel, IonIcon
} from '@ionic/react';
import Spinner from "../../components/Spinner";
import ResponseMessage from "../../components/ResponseMessage";
import AuthLayout from "../layout/AuthLayout";
import {useParams} from "react-router";
import {openCity} from "../../helpers/NewCityHelper";
import {useFetch} from "../../hooks/useFetch";
import {appSettings} from "../../global/AppSettings";
import {handleInputChange} from "../../utility/InputChangeHandler";
import {usePost} from "../../hooks/usePost";
import {hideParagraphTag} from "../../utility/ParagraphMaker";
import {addOutline, removeOutline} from "ionicons/icons";

const UpdateEvent = () => {
    const [isCustomCity, setIsCustomCity] = useState(false);
    const [variablePriceCount, setVariablePriceCount] = useState(0);
    const [variablePriceArray, setVariablePriceArray] = useState([]);
    const [variablePriceCaptionArray, setVariablePriceCaptionArray] = useState([]);
    const[isPaidEvent, setIsPaidEvent] = useState(false);
    const [existingVariablePriceCount , setExistingVariablePriceCount] = useState(null);

    const [cities, setCities] = useState(null);
    const [countries, setCountries] = useState(null);
    const [entryModes, setEntryModes] = useState(null);
    const [eventCategories, setEventCategories] = useState(null);
    const [contentArray, setContentArray] = useState(null);
    const [existingContentArray, setExistingContentArray] = useState(null);

    // @ts-ignore
    //FETCH EVENT BY ID
    const {eventId}  = useParams();
    const [data,isLoading, setData] = useFetch(`api/event/${eventId}`);
    //FETCH CITY ,COUNTRY
    const [eventGroupedData] = useFetch(`api/event-data`);
    //INPUT CHANGE HANDLER
    const [textAreaData, setTextAreaData] = useState(null);

    useEffect(() => {
        if(data){
            if(data.entryMode == "TICKET"){
                setIsPaidEvent(true);
            }

            setExistingVariablePriceCount(data.variablePricesAndCaptions.length);

            if(!existingContentArray){
                const exiStingContent = Array(data.variablePricesAndCaptions.length).fill('content'); // Create an array with 'content' based on num of variable price count
                setExistingContentArray(exiStingContent)
            }

        }
    },[data])
    const handleChange = (e) => {
        handleInputChange (e, data, setData, textAreaData ,setTextAreaData)
        openCity(e , setIsCustomCity );

        //PRICE TOGGLING
        if(e.target.name == "entryMode"){
            if(e.target.value == "TICKET"){
                setIsPaidEvent(true);
            }
            else {
                setIsPaidEvent(false);
            }
        }
    };

    const handleIndexedInputChange = (e, index) =>{
        const { name, value } = e.target;


        if(name == "variablePrices"){
            const newVariablePrices = [...variablePriceArray];
            newVariablePrices[index] = value;
            console.log(newVariablePrices)
            setVariablePriceArray(newVariablePrices);

            setData({ ...data, [name]: newVariablePrices });

        }else{
            const newVariablePriceCaptions = [...variablePriceCaptionArray];
            newVariablePriceCaptions[index] = value;
            //alert(value);
            console.log(newVariablePriceCaptions);
            setData({ ...data, [name]: newVariablePriceCaptions });
            setVariablePriceCaptionArray(newVariablePriceCaptions);
        }
    }

    // const handleExistingDataInputChange = (e, index) =>{
    //     const { name, value } = e.target;
    //
    //     alert(index);
    //     if(name == "variablePrices"){
    //         const newVariablePrices = [...variablePriceArray];
    //         newVariablePrices[index] = value;
    //         console.log(newVariablePrices)
    //         setVariablePriceArray(newVariablePrices);
    //
    //         setData({ ...data, [name]: newVariablePrices });
    //
    //     }else{
    //         const newVariablePriceCaptions = [...variablePriceCaptionArray];
    //         newVariablePriceCaptions[index] = value;
    //         //alert(value);
    //         console.log(newVariablePriceCaptions);
    //         setData({ ...data, [name]: newVariablePriceCaptions });
    //         setVariablePriceCaptionArray(newVariablePriceCaptions);
    //     }
    // }

    const decrementTicket = () => {
        if (variablePriceCount > 0) {
            setVariablePriceCount(variablePriceCount - 1);
        }
    }
    const incrementTicket = () => {
        setVariablePriceCount(variablePriceCount + 1);
    }


    useEffect(() => {
        const content = Array(variablePriceCount).fill('content'); // Create an array with 'content' based on num of variable price count
        setContentArray(content);
    },[variablePriceCount])

    //CALL POST CUSTOM HOOK
    const {isSpinning,postData} = usePost(`api/event/${eventId}?_method=PUT`);

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        const updatedData = {...data, ...textAreaData}
        postData(updatedData);
    }

    return (
        <AuthLayout>
            {data &&
                <form onSubmit={handleSubmit}>
                    <h4>Update Event</h4>
                    <div>
                        <select aria-label="eventCategory *"
                                name ="eventCategoryId"
                                placeholder="Event Category"
                                onChange = {handleChange}
                                required
                                value={data.eventCategoryId}
                        >
                            {eventGroupedData && eventGroupedData.eventCategories.eventCategories.map((eventCategory, key)=>(
                                <option value= {eventCategory["id"]} key ={key}>{eventCategory["eventCategoryName"]}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <IonInput
                            label="Title *"
                            labelPlacement="floating"
                            type="text"
                            name = "eventTitle"
                            onIonChange = {handleChange}
                            value ={data.eventTitle}
                            required>
                        </IonInput>
                    </div>

                    <div>
                    <textarea
                        aria-label="Description"
                        name="eventDescription"
                        onChange = {handleChange}
                        placeholder ="Event description"
                        rows = {appSettings.textAreaHeight}
                        value={`${hideParagraphTag(data.eventDescription)}`}
                        required
                    >
                    </textarea>
                        {/*<IonInput*/}
                        {/*    label="Description"*/}
                        {/*    labelPlacement="floating"*/}
                        {/*    type="text"*/}
                        {/*    name="eventDescription"*/}
                        {/*    onIonChange = {handleChange}*/}
                        {/*    required>*/}
                        {/*</IonInput>*/}
                    </div>

                    <div>
                        <IonInput
                            label="Venue *"
                            labelPlacement="floating"
                            type="text"
                            name={"eventVenue"}
                            onIonChange = {handleChange}
                            value = {data.eventVenue}
                            required
                        ></IonInput>
                    </div>

                    <div className="custom-item-align custom-special-input">
                        <label><span>Start Date</span><br/>
                            <input
                                label="Start Date"
                                labelPlacement="stacked"
                                type="date"
                                name="eventDateStart"
                                onChange = {handleChange}
                                value = {data.eventDateStart}
                            >
                            </input>
                        </label>

                        <label className="custom-text-right"><span>End Date</span><br/>
                            <input
                                label="End Date"
                                labelPlacement="stacked"
                                type="date"
                                name="eventDateEnd"
                                onChange = {handleChange}
                                value = {data.eventDateEnd}
                            >
                            </input>
                        </label>

                    </div>

                    <div className="custom-item-align custom-special-input">
                        <label>
                            <span>Start Time</span><br/>
                            <input label="Start Time"
                                   labelPlacement="stacked"
                                   placeholder="Enter text"
                                   type = "time"
                                   name = "eventTimeStart"
                                   onChange = {handleChange}
                                   value = {data.eventTimeStart}
                            >

                            </input>
                        </label>

                        <label className="custom-text-right">
                            <span className="custom-text-right">End Time</span><br/>
                            <input label="End Time"
                                   labelPlacement="stacked"
                                   placeholder="Enter text"
                                   type = "time"
                                   name = "eventTimeEnd"
                                   onChange = {handleChange}
                                   value = {data.eventTimeEnd}
                            >

                            </input>
                        </label>

                    </div>

                    <div>
                        <IonInput
                            label="Event Capacity"
                            labelPlacement="floating"
                            type="number"
                            name="eventVenueCapacity"
                            onIonChange = {handleChange}
                            value = {data.eventVenueCapacity}
                        ></IonInput>
                    </div>

                    <div>
                        <select aria-label="country *"
                                name ="country"
                                placeholder="Country"
                                onChange = {handleChange}
                                value={data.country}
                        >
                            <option value="">Select Country</option>
                            {eventGroupedData && eventGroupedData.countries.map((country, key) => (
                                <option value= {country["Country"]} key ={key}>{country["Country"]}</option>
                            ))}

                        </select>
                    </div>

                    <div>
                        <select aria-label="city *"
                                name ="cityName"
                                placeholder="City"
                                onChange = {handleChange}
                                value={data.cityName}
                        >
                            {/*@ts-ignore*/}
                            <option value="">Select City</option>
                            {eventGroupedData && eventGroupedData.cities.cities.map((city, key) => (
                                <option
                                    value= {city["cityName"]}
                                    key ={key}>{city["cityName"]}
                                </option>
                            ))}

                            <option value= "Others">
                                Others
                            </option>

                        </select>
                    </div>

                    {isCustomCity &&
                        <>
                            <div>
                                <IonInput
                                    label="City"
                                    labelPlacement="floating"
                                    type="text"
                                    name="cityName"
                                    onIonChange = {handleChange}
                                    required
                                ></IonInput>
                            </div>

                            <div className="custom-special-input">
                                <label>
                                    <span>City Image</span>
                                    <input
                                        type="file"
                                        name="cityImageUrl"
                                        onChange={handleChange}
                                    />
                                </label>

                            </div>
                        </>}

                    <div>
                        <IonInput
                            label="Latitude"
                            labelPlacement="floating"
                            type="text"
                            name="latitude"
                            onIonChange = {handleChange}
                            value = {data.latitude}
                        ></IonInput>
                    </div>

                    <div>
                        <IonInput
                            label="Longitude"
                            labelPlacement="floating"
                            type="text"
                            name="longitude"
                            onIonChange = {handleChange}
                            value = {data.longitude}
                        ></IonInput>
                    </div>

                    <div>
                        <select aria-label="entryMode *"
                                name = "entryMode"
                                placeholder = "Entry Mode"
                                onChange = {handleChange}
                                value={data.entryMode}
                        >
                            <option value="">Select Entry Mode</option>
                            {eventGroupedData && eventGroupedData.entryModes.map((entryMode, key) => (
                                <option value= {entryMode} key ={key}>{entryMode}</option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <IonInput
                            label="Max Ticket"
                            labelPlacement="floating"
                            type="number"
                            name="maxTicket"
                            onIonChange = {handleChange}
                            value = {data.maxTicket}
                        ></IonInput>
                    </div>

                    {existingContentArray && existingContentArray.map((content, index) => (
                        <div className="custom-item-align" key = {index}>
                            <div className="custom-full-width">
                                <IonInput
                                    label="Caption e.g VIP"
                                    labelPlacement="floating"
                                    type="text"
                                    name="variablePriceCaptions"
                                    onIonInput = {(e) => handleIndexedInputChange(e,index)}
                                    className=""
                                    value = {data.variablePriceCaptions[index]}

                                ></IonInput>
                            </div>
                            <div className="custom-full-width">
                                <IonInput
                                    label="Price e.g $20"
                                    labelPlacement="floating"
                                    type="number"
                                    name="variablePrices"
                                    onIonInput = {(e) => handleIndexedInputChange(e,index)}
                                    className=""
                                    value = {data.variablePrices[index]}
                                ></IonInput>
                            </div>
                        </div>
                    ))
                    }

                    {isPaidEvent &&
                        <>
                        {(data.variablePrices.length === 0) &&
                            <div>
                                <IonInput
                                    label="Price e.g $20"
                                    labelPlacement="floating"
                                    type="text"
                                    name="price"
                                    onIonInput = {handleChange}
                                    value = {data.price}
                                ></IonInput>
                            </div>
                        }

                            <div className="custom-item-align" style={{marginTop:"10px"}}>
                                <div className="custom-full-width"><span>Variable Prices</span></div>
                                <div onClick={decrementTicket} className="dark-tint-bg round-circle inc-dec-button text-orange">
                                    <IonIcon aria-hidden="true" icon={removeOutline} size="large"/>
                                </div>
                                <p style={{fontWeight:"bold",fontSize:"1.5em"}}>{variablePriceCount}</p>
                                <div onClick={incrementTicket} className="dark-shade-bg round-circle inc-dec-button text-orange">
                                    <IonIcon aria-hidden="true" icon={addOutline} size="large"/>
                                </div>
                            </div>
                            {variablePriceCount > 0 && contentArray && contentArray.map((content, index) => (
                                <div className="custom-item-align" key = {index+existingVariablePriceCount}>
                                    <div className="custom-full-width">
                                        <IonInput
                                            label="Caption e.g VIP"
                                            labelPlacement="floating"
                                            type="text"
                                            name="variablePriceCaptions"
                                            onIonInput = {(e) => handleIndexedInputChange(e,index+existingVariablePriceCount)}
                                            className=""
                                        ></IonInput>
                                    </div>
                                    <div className="custom-full-width">
                                        <IonInput
                                            label="Price e.g $20"
                                            labelPlacement="floating"
                                            type="number"
                                            name="variablePrices"
                                            onIonInput = {(e) => handleIndexedInputChange(e,index+existingVariablePriceCount)}
                                            className=""
                                        ></IonInput>
                                    </div>
                                </div>
                            ))
                            }
                        </>
                    }

                    <div className="custom-special-input">
                        <label className="text-white">
                            <span>Venue &nbsp;</span>
                            <input
                                type="file"
                                name="eventVenueImageUrl"
                                onChange={handleChange}
                            />
                        </label>
                    </div>

                    <div className="custom-special-input">
                        <label className="text-white">
                            <span>Event &nbsp;</span>
                            <input
                                type="file"
                                name="eventImageUrl"
                                onChange={handleChange}
                            />
                        </label>
                    </div>

                    <button shape="round"
                            color="danger"
                            expand="block"
                            type="submit"
                            className="ion-margin-top"
                    >
                        Add Event
                        {isSpinning && <Spinner/>}
                    </button>
                </form>
            }


            {/*{data &&*/}
            {/*    <form onSubmit={handleSubmit}>*/}
            {/*        <h2>Event Update</h2>*/}
            {/*        <IonItem>*/}
            {/*            <IonSelect aria-label="eventCategory *"*/}
            {/*                       name ="eventCategoryId"*/}
            {/*                       placeholder="Event Category"*/}
            {/*                       onIonChange = {handleChange}*/}
            {/*            >*/}
            {/*                /!*@ts-ignore*!/*/}
            {/*                {eventGroupedData && eventGroupedData.eventCategories.eventCategories.map((eventCategory, key)=>(*/}
            {/*                    <IonSelectOption value= {eventCategory["id"]} key ={key}>{eventCategory["eventCategoryName"]}</IonSelectOption>*/}
            {/*                ))}*/}
            {/*            </IonSelect>*/}
            {/*        </IonItem>*/}
            {/*        <IonItem>*/}
            {/*            <IonInput*/}
            {/*                label="Title *"*/}
            {/*                labelPlacement="floating"*/}
            {/*                type="text"*/}
            {/*                name = "eventTitle"*/}
            {/*                onIonChange = {handleChange}*/}
            {/*                value = {data.eventTitle}*/}
            {/*                required>*/}
            {/*            </IonInput>*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <textarea*/}
            {/*                aria-label="Description"*/}
            {/*                name="eventDescription"*/}
            {/*                onChange = {handleChange}*/}
            {/*                required*/}
            {/*                rows = {appSettings.textAreaHeight}*/}
            {/*                className="custom-textarea"*/}
            {/*                value={`${hideParagraphTag(data.eventDescription)}`}*/}
            {/*            >*/}
            {/*            </textarea>*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <IonInput*/}
            {/*                label="Venue *"*/}
            {/*                labelPlacement="floating"*/}
            {/*                type="text"*/}
            {/*                name="eventVenue"*/}
            {/*                onIonChange = {handleChange}*/}
            {/*                value = {data.eventVenue}*/}
            {/*                required*/}
            {/*            ></IonInput>*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <IonSelect aria-label="entryMode *"*/}
            {/*                       name = "entryMode"*/}
            {/*                       placeholder = "Entry Mode"*/}
            {/*                       onIonChange = {handleChange}*/}
            {/*            >*/}
            {/*                /!*@ts-ignore*!/*/}
            {/*                {eventGroupedData && eventGroupedData.entryModes.map((entryMode, key) => (*/}
            {/*                    <IonSelectOption value= {entryMode} key ={key}>{entryMode}</IonSelectOption>*/}
            {/*                ))}*/}
            {/*            </IonSelect>*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <IonInput*/}
            {/*                label="Start Date"*/}
            {/*                labelPlacement="stacked"*/}
            {/*                type="date"*/}
            {/*                name="eventDateStart"*/}
            {/*                onIonChange = {handleChange}*/}
            {/*                value = {data.eventDateStart}*/}
            {/*            >*/}
            {/*            </IonInput>*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <IonInput*/}
            {/*                label="End Date"*/}
            {/*                labelPlacement="stacked"*/}
            {/*                type="date"*/}
            {/*                name="eventDateEnd"*/}
            {/*                onIonChange = {handleChange}*/}
            {/*                value = {data.eventDateEnd}*/}
            {/*            >*/}
            {/*            </IonInput>*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <IonInput label="Start Time"*/}
            {/*                      labelPlacement="stacked"*/}
            {/*                      placeholder="Enter text"*/}
            {/*                      type = "time"*/}
            {/*                      name = "eventTimeStart"*/}
            {/*                      onIonChange = {handleChange}*/}
            {/*                      value = {data.eventTimeStart}*/}
            {/*            >*/}

            {/*            </IonInput>*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <IonInput label="End Time"*/}
            {/*                      labelPlacement="stacked"*/}
            {/*                      placeholder="Enter text"*/}
            {/*                      type = "time"*/}
            {/*                      name = "eventTimeEnd"*/}
            {/*                      onIonChange = {handleChange}*/}
            {/*                      value = {data.eventTimeEnd}*/}
            {/*            >*/}

            {/*            </IonInput>*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <IonInput*/}
            {/*                label="Event Capacity"*/}
            {/*                labelPlacement="floating"*/}
            {/*                type="number"*/}
            {/*                name="eventVenueCapacity"*/}
            {/*                onIonChange = {handleChange}*/}
            {/*                value = {data.eventVenueCapacity}*/}
            {/*            ></IonInput>*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <IonSelect aria-label="country *"*/}
            {/*                       name ="country"*/}
            {/*                       placeholder="Country"*/}
            {/*            >*/}
            {/*                /!*@ts-ignore*!/*/}
            {/*                {eventGroupedData && eventGroupedData.countries.map((country, key) => (*/}
            {/*                    <IonSelectOption value= {country["Country"]} key ={key}>{country["Country"]}</IonSelectOption>*/}
            {/*                ))}*/}

            {/*            </IonSelect>*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <IonSelect aria-label="city *"*/}
            {/*                       name ="city"*/}
            {/*                       placeholder="City"*/}
            {/*                       onIonChange = {handleChange}*/}
            {/*            >*/}
            {/*                /!*@ts-ignore*!/*/}
            {/*                {eventGroupedData && eventGroupedData.cities.cities.map((city, key) => (*/}
            {/*                    <IonSelectOption*/}
            {/*                        value= {city["cityName"]}*/}
            {/*                        key ={key}>{city["cityName"]}*/}
            {/*                    </IonSelectOption>*/}
            {/*                ))}*/}

            {/*                <IonSelectOption value= "Others">*/}
            {/*                    Others*/}
            {/*                </IonSelectOption>*/}

            {/*            </IonSelect>*/}
            {/*        </IonItem>*/}

            {/*        {isCustomCity &&*/}
            {/*            <>*/}
            {/*                <IonItem>*/}
            {/*                    <IonInput*/}
            {/*                        label="City"*/}
            {/*                        labelPlacement="floating"*/}
            {/*                        type="text"*/}
            {/*                        name="cityName"*/}

            {/*                        onIonChange = {handleChange}*/}
            {/*                        required*/}
            {/*                    ></IonInput>*/}
            {/*                </IonItem>*/}

            {/*                <IonItem>*/}
            {/*                    <IonLabel>*/}
            {/*                        City Image*/}
            {/*                    </IonLabel>*/}
            {/*                    <input*/}
            {/*                        type="file"*/}
            {/*                        name="cityImageUrl"*/}
            {/*                        onChange={handleChange}*/}
            {/*                    />*/}
            {/*                </IonItem>*/}
            {/*            </>}*/}

            {/*        <IonItem>*/}
            {/*            <IonInput*/}
            {/*                label="Latitude"*/}
            {/*                labelPlacement="floating"*/}
            {/*                type="text"*/}
            {/*                name="latitude"*/}
            {/*                onInput = {handleChange}*/}
            {/*                value = {data.latitude}*/}
            {/*            ></IonInput>*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <IonInput*/}
            {/*                label="Longitude"*/}
            {/*                labelPlacement="floating"*/}
            {/*                type="text"*/}
            {/*                name="longitude"*/}
            {/*                onIonChange = {handleChange}*/}
            {/*                value = {data.longitude}*/}
            {/*            ></IonInput>*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <IonInput*/}
            {/*                label="Price"*/}
            {/*                labelPlacement="floating"*/}
            {/*                type="text"*/}
            {/*                name="price"*/}
            {/*                onIonChange = {handleChange}*/}
            {/*                value = {data.price}*/}

            {/*            ></IonInput>*/}
            {/*        </IonItem>*/}
            {/*        <IonItem>*/}
            {/*            <IonLabel>*/}
            {/*                Venue*/}
            {/*            </IonLabel>*/}
            {/*            <input*/}
            {/*                type="file"*/}
            {/*                name="eventVenueImageUrl"*/}
            {/*                onChange={handleChange}*/}
            {/*            />*/}
            {/*        </IonItem>*/}

            {/*        <IonItem>*/}
            {/*            <IonLabel>*/}
            {/*                Event*/}
            {/*            </IonLabel>*/}
            {/*            <input*/}
            {/*                type="file"*/}
            {/*                name="eventImageUrl"*/}
            {/*                onChange={handleChange}*/}
            {/*            />*/}
            {/*        </IonItem>*/}


            {/*        <IonButton shape="round"*/}
            {/*                   color="danger"*/}
            {/*                   expand="block"*/}
            {/*                   type="submit"*/}
            {/*                   className="ion-margin-top"*/}
            {/*        >*/}
            {/*            Update Event*/}
            {/*            {isSpinning && <Spinner/>}*/}
            {/*        </IonButton>*/}
            {/*    </form>*/}
            {/*}*/}

            <ResponseMessage/>
        </AuthLayout>
    );
};

export default UpdateEvent;
