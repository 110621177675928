import React, {useContext, useState} from 'react';
import {IonButton, IonCol, IonContent, IonGrid, IonRow} from "@ionic/react";
import GuestLayout from "../layout/GuestLayout";
import Spinner from "../../components/Spinner";
import {useHistory} from "react-router";
import {MyContext} from "../../statemanagement/ComponentState";
import axios from "axios";
import {baseUrl, bgClassColors, headers} from "../../global/Config";
import Invoice from "./Invoice";
import AuthLayout from "../layout/AuthLayout";

function TicketOrder() {
    const {setResponseMessage,
        isSpinning, setIsSpinning,
        setIsOpen} = useContext(MyContext);
    const [setResponseData] = useState(null);
    const data =JSON.parse(localStorage.getItem("ticketDetails"));
    const history = useHistory();


    const postData = (formData) => {
        setIsSpinning(true);
        console.log(formData)
        axios.post(`${baseUrl}/api/buy-ticket`, formData, headers)
            .then(res => {
                console.log(res);
                setIsSpinning(false);

                if(data.ticketType !=="FREE"){
                    localStorage.setItem("paymentToken", JSON.stringify(res.data));
                    history.push("/payment");
                    //window.location.href="/payment";
                }
                else{
                    localStorage.removeItem("ticketDetails");
                    window.location.href="/my-ticket";
                }

            })
            .catch(err => {
                setIsSpinning(false);
                setIsOpen(true);
                //setResponseMessage(["Error !", err.message]);
                console.log(err);
            });
    }

    const getPaidOrFreeTicket = () => {
        postData(data);
    }

    return (
        <AuthLayout>
            <IonContent className="ion-padding">
                <Invoice />

                {data.ticketType !== "FREE" &&
                    <button shape="round"
                               color="danger"
                               expand="block"
                               type="button"
                               className="ion-margin-top custom-get-ticket-button"
                               onClick = {getPaidOrFreeTicket}>Checkout
                        {isSpinning && <Spinner/>}
                    </button>
                }

                {data.ticketType == "FREE" &&
                    <button shape="round"
                               color="danger"
                               expand="block"
                               type="button"
                               className="ion-margin-top custom-get-ticket-button"
                               onClick = {getPaidOrFreeTicket}>Get Ticket Now
                        {isSpinning && <Spinner/>}
                    </button>
                }

            </IonContent>
        </AuthLayout>

    );
}

export default TicketOrder;