import React, {useContext, useEffect, useState} from 'react';
import AuthLayout from "../layout/AuthLayout";
import {IonInput} from "@ionic/react";
import {CompanyRequestData} from "../../data/request/CompanyRequestData";
import {handleInputChange} from "../../utility/InputChangeHandler";
import {MyContext} from "../../statemanagement/ComponentState";
import axios from "axios";
import {baseUrl} from "../../global/Config";
import Spinner from "../../components/Spinner";
import ResponseMessage from "../../components/ResponseMessage";
import {useHistory} from "react-router";
import LoadingAnimation from "../../components/LoadingAnimation";

function Setting() {
    const [textAreaData, setTextAreaData] = useState(null);
    const [data , setData] = useState(null);
    const history = useHistory();
    const getCompanyInfo = () => {
        setIsSpinning(true);
        console.log(data)
        axios.get(`${baseUrl}/api/company`)
            .then(res => {
                setIsSpinning(false);
                console.log(res.data)
                setData(res.data);

            })
            .catch(err => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Error !", err.response.data.message]);
                console.log(err);
            });
    }

    useEffect(() => {
        getCompanyInfo();
    }, [])

    const [isSpinning, setIsSpinning] = useState(false);
    const {isOpen, setIsOpen, setResponseMessage} = useContext(MyContext);
    const handleChange = (e) => {
        // @ts-ignore
        handleInputChange(e, data, setData, textAreaData, setTextAreaData)
    }

    const updateCompanyInfo = () => {
        setIsSpinning(true);
        console.log(data)
        axios.post(`${baseUrl}/api/company/1?_method=PUT`, data)
            .then(res => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Success !", "Settings updated"]);
               // history.goBack(); // This will go back to the previous page

            })
            .catch(err => {
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Error !", err.response.data.message]);
                console.log(err);
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        updateCompanyInfo();
    }


    const  companyProfileRegistrationForm = <>
        <form onSubmit={handleSubmit}>
            <h4>Settings</h4>

            <div>
                <IonInput
                    label="Company Name"
                    labelPlacement="floating"
                    type="text"
                    name = "companyName"
                    onIonInput = {handleChange}
                >
                </IonInput>
            </div>

            <div>
                <IonInput
                    label="Company Email"
                    labelPlacement="floating"
                    type="email"
                    name = "companyEmail"
                    onIonInput = {handleChange}
                >
                </IonInput>
            </div>

            <div>
                <IonInput
                    label="Company Phones"
                    labelPlacement="floating"
                    type="text"
                    name = "companyPhoneNumbers"
                    onIonInput = {handleChange}
                >
                </IonInput>
            </div>

            <div>
                <IonInput
                    label="Lat & Long (e.g 100000 , 100011)"
                    labelPlacement="floating"
                    type="text"
                    name = "companyLatitudeAndLongitude"
                    onIonInput = {handleChange}
                >
                </IonInput>
            </div>

            <div>
                <IonInput
                    label="Company Website"
                    labelPlacement="floating"
                    type="text"
                    name = "companyWebsite"
                    onIonInput = {handleChange}
                >
                </IonInput>
            </div>

            <br/>
            <div>
                <label>Address</label>
                <textarea
                    label="Address"
                    name = "companyAddress"
                    onChange = {handleChange}
                    rows={3}
                >
                    </textarea>
            </div>
            <br/>
            <div>
                <label>Privacy Policy</label>
                <textarea
                    label="Privacy Policy"
                    name = "companyPrivacyPolicy"
                    onChange = {handleChange}
                    rows={20}
                >
                    </textarea>
            </div>

            <button type="submit">Update {isSpinning && <Spinner />}</button>
        </form>
    </>

    const  companyProfileUpdateForm = <>
    {data &&
        <form onSubmit={handleSubmit}>
            <h4>Settings</h4>

            <div>
                <IonInput
                    label="Company Name"
                    labelPlacement="floating"
                    type="text"
                    name = "companyName"
                    onIonInput = {handleChange}
                    value = {data.companyName}
                >
                </IonInput>
            </div>

            <div>
                <IonInput
                    label="Company Email"
                    labelPlacement="floating"
                    type="email"
                    name = "companyEmail"
                    onIonInput = {handleChange}
                    value = {data.companyEmail}
                >
                </IonInput>
            </div>

            <div>
                <IonInput
                    label="Company Phones"
                    labelPlacement="floating"
                    type="text"
                    name = "companyPhoneNumbers"
                    onIonInput = {handleChange}
                    value = {data.companyPhoneNumbers}
                >
                </IonInput>
            </div>

            <div>
                <IonInput
                    label="Lat & Long (e.g 100000 , 100011)"
                    labelPlacement="floating"
                    type="text"
                    name = "companyLatitudeAndLongitude"
                    onIonInput = {handleChange}
                    value = {data.companyLatitudeAndLongitude}
                >
                </IonInput>
            </div>

            <div>
                <IonInput
                    label="Company Website"
                    labelPlacement="floating"
                    type="text"
                    name = "companyWebsite"
                    onIonInput = {handleChange}
                    value = {data.companyWebsite}
                >
                </IonInput>
            </div>

            <br/>
            <div>
                <label className="text-white">Address</label>
                <textarea
                    label="Address"
                    name = "companyAddress"
                    onChange = {handleChange}
                    value = {data.companyAddress}
                    rows={3}
                >
                    </textarea>
            </div>
            <br/>
            <div>
                <label className="text-white">Privacy Policy</label>
                <textarea
                    label="Privacy Policy"
                    name = "companyPrivacyPolicy"
                    onChange = {handleChange}
                    value = {data.companyPrivacyPolicy}
                    rows={20}
                >
                    </textarea>
            </div>

            <button type="submit">Update {isSpinning && <Spinner />}</button>
        </form>
    }
    </>

    return (
        <AuthLayout>
            {isSpinning && <LoadingAnimation/>}
            {data && companyProfileUpdateForm}
            {!data && companyProfileRegistrationForm}
            {isOpen && <ResponseMessage/>}
        </AuthLayout>
    );
}
export default Setting;