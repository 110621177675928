import React, {useContext, useEffect, useState} from 'react';
import GuestLayout from "../../layout/GuestLayout";
import {paymentToken} from "../../../storage/PaymentToken";
import axios from "axios";
import {baseUrl, bgClassColors, headers} from "../../../global/Config";
import {MyContext} from "../../../statemanagement/ComponentState";
import Spinner from "../../../components/Spinner";
import ResponseMessage from "../../../components/ResponseMessage";
import {IonCard, IonCardContent, IonCol, IonGrid, IonRow} from "@ionic/react";

function PaymentComplete() {
    const {setIsOpen, setResponseMessage, isSpinning, setIsSpinning} = useContext(MyContext);
    const [ticketPaymentDetail, setTicketPaymentDetail] = useState(null)

//     const url = window.location.href;
//
//     // Extract parameters from the URL
//     const urlParams = new URLSearchParams(url.split("?")[1]);
//
// // Create an object using the extracted parameters
//     const paymentWebhookParam = {
//         payment_intent: urlParams.get("payment_intent"),
//         payment_intent_client_secret: urlParams.get("payment_intent_client_secret"),
//         redirect_status: urlParams.get("redirect_status")
//     };

    //console.log(paymentWebhookParam);
    useEffect(() => {
        setIsSpinning(true);
        axios.get(`${baseUrl}/api/complete/${paymentToken.orderReferenceNum}`, headers
        )
            .then(res => {
                const data = res.data
                setTicketPaymentDetail(data);
                console.log(data);
                setIsSpinning(false);
                setIsOpen(true);
                setResponseMessage(["Successful !", "Payment successful"]);

                localStorage.removeItem("ticketDetails");
            })
            .catch(err => {
                console.log(err);
                setResponseMessage(["Error !", err.response.data.message]);
            });
    },[])

    return (
            <GuestLayout>{isSpinning && <Spinner/>}
                {ticketPaymentDetail &&
                    <IonCard style={{color:"#000"}}>
                        <IonCardContent>
                            <h3>Ticket Details</h3>
                            <IonGrid className = "record">
                                <IonRow className={bgClassColors[0]}>
                                    <IonCol size="4">Ticket ID</IonCol>
                                    <IonCol>{ticketPaymentDetail.ticketOrderRef}</IonCol>
                                </IonRow>
                                <IonRow className={bgClassColors[1]}>
                                    <IonCol size="4">Event Title</IonCol>
                                    <IonCol >{ticketPaymentDetail.eventTitle}</IonCol>
                                </IonRow>
                                <IonRow className={bgClassColors[0]}>
                                    <IonCol size="4">Date</IonCol>
                                    <IonCol >{ticketPaymentDetail.eventDate}</IonCol>
                                </IonRow>
                                <IonRow className={bgClassColors[1]}>
                                    <IonCol size="4">Time</IonCol>
                                    <IonCol >{ticketPaymentDetail.eventTime}</IonCol>
                                </IonRow>
                                <IonRow className={bgClassColors[0]}>
                                    <IonCol size="4">Qty</IonCol>
                                    <IonCol >{ticketPaymentDetail.totalTickets}</IonCol>
                                </IonRow>
                                <IonRow className={bgClassColors[1]}>
                                    <IonCol size="4">Total Price</IonCol>
                                    <IonCol ><span dangerouslySetInnerHTML= {{ __html: ticketPaymentDetail.totalCharges}}></span></IonCol>
                                </IonRow>
                                <IonRow className={bgClassColors[0]}>
                                    <IonCol size="4">Status</IonCol>
                                    <IonCol >{ticketPaymentDetail.paymentStatus}</IonCol>
                                </IonRow>

                            </IonGrid>
                        </IonCardContent>
                    </IonCard>

                }
                <ResponseMessage/>
            </GuestLayout>

    );
}


export default PaymentComplete;