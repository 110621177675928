import React, {useEffect, useState} from 'react';
import {IonCol, IonGrid, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonThumbnail} from '@ionic/react';
import {useHistory} from "react-router";
import {useFetch} from "../hooks/useFetch";
import {hideParagraphTag} from "../utility/ParagraphMaker";
import {isKeyPresent} from "../helpers/ObjectKey";
import {eventFilterRequestData} from "../data/request/EventFilterData";

function EventFilter() {

    const [categoryResponse] = useFetch(`api/event-categories`);
    const [cityResponse] = useFetch(`api/cities`);

    const [eventFilterData, setEventFilterData] = useState({...eventFilterRequestData});

    const history = useHistory();

    // const handleChange = (e) => {
    //     const city = e.target.value;
    //
    //     history.push(`/even-by-city/${city}`); // Navigate to the specified route
    //
    // }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const data = { ...eventFilterData, [name]: value }
        //localStorage.setItem("eventFilterData", JSON.stringify(data));
        setEventFilterData({ ...eventFilterData, [name]: value });

        localStorage.setItem("eventFilterData", JSON.stringify(data));

    };



    const gotoEventCategory = (link) =>{
       // window.location.href = link;
        history.push(link)
    }

    let eventFilterDataFromStorage =  JSON.parse(localStorage.getItem("eventFilterData"));

    if(!(eventFilterDataFromStorage)){
        eventFilterDataFromStorage ={
            cityName : null,
            sortBy : null,
            dateFrom : null,
            dateTo : null
        }
    }

    console.log(eventFilterDataFromStorage);
    return (
        <>
            <br/>
            <div className="custom-select2">
                <select onChange= {handleChange}
                        name="cityName"
                        value={eventFilterDataFromStorage.cityName}
                >
                    <option value="">City</option>
                    {
                        cityResponse && cityResponse.cities.map((city , key) =>(
                            <option key = {key} value={city["cityName"]}>{city["cityName"]}</option>
                        ))
                    }
                </select>
            </div>

            <div className="custom-select2" style={{marginTop:"30px"}}>
                <select name="sortBy" onChange= {handleChange} value={eventFilterDataFromStorage.sortBy}>
                    <option value="">Sort by</option>
                    {/*<option value={eventFilterRequestData.sortBy}>{eventFilterRequestData.sortBy}</option>*/}
                    <option value="Closest Distance">Closest Distance</option>
                    <option value="Event Date">Closest Date</option>
                </select>
            </div>

            <div className="custom-item-alig custom-special-input custom-date"
            >
                <label><span>Start Date</span><br/>
                    <input
                        label="Start Date"
                        labelPlacement="stacked"
                        type="date"
                        name="dateFrom"
                        onChange = {handleChange}
                        value={eventFilterDataFromStorage.dateFrom}
                    >
                    </input>
                </label><br/><br/>

                <label className="custom-text-right"><span>End Date</span><br/>
                    <input
                        label="End Date"
                        labelPlacement="stacked"
                        type="date"
                        name="dateTo"
                        onChange = {handleChange}
                        value={eventFilterDataFromStorage.dateTo}
                    >
                    </input>
                </label>
            </div>
            <br/>

            {categoryResponse && categoryResponse.eventCategories.map((eventCategory , key) =>(
                <IonItem detail={true}
                         onClick={()=>gotoEventCategory(`/even-by-category/${eventCategory['id']}/${eventCategory["eventCategoryName"]}`)}
                         key = {key}
                         className="custom-pointer"
                >
                    <IonThumbnail slot="start">
                        <img alt="Silhouette of mountains" src={eventCategory["eventCategoryImage"]} />
                    </IonThumbnail>
                    <IonLabel>
                        <h3>{eventCategory["eventCategoryName"]}</h3>
                        {hideParagraphTag(eventCategory["eventCategoryDescription"])}
                    </IonLabel>
                </IonItem>
            ))}
        </>
    );
}
export default EventFilter;