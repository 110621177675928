import React, { createContext, useState } from 'react';
// @ts-ignore
const MyContext = createContext();

// @ts-ignore
const MyContextProvider = ({ children }) => {
    const [pageTitle, setPageTitle] = useState(null);
    const [token , setToken] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isSpinning, setIsSpinning] = useState(false);

    const [isSuccessMessageBox, setIsSuccessMessageBox] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isFailureMessageBox, setIsFailureMessageBox] = useState(false);
    const [failureMessage, setFailureMessage] = useState(null);


    const [isSearchBox, setIsSearchBox] = useState(false);
    const [pageName, setPageName] = useState("");

    const [searchParam, setSearchParam] =useState(null);
    const [isAdmin , setIsAdmin] = useState(false);
    const [authenticatedUserDetails , setAuthenticatedUserDetails] = useState({});
    const [responseMessage, setResponseMessage] = useState([]);
    return (
        <MyContext.Provider value={{
            pageTitle, setPageTitle,
            isModal, setIsModal,
            isOpen, setIsOpen,
            isSpinning, setIsSpinning,
            isSuccessMessageBox,setIsSuccessMessageBox,
            successMessage, setSuccessMessage,
            isFailureMessageBox, setIsFailureMessageBox,
            failureMessage, setFailureMessage,
            token , setToken,
            isSearchBox, setIsSearchBox,
            pageName, setPageName,
            searchParam, setSearchParam,
            isAdmin , setIsAdmin,
            authenticatedUserDetails , setAuthenticatedUserDetails,
            responseMessage, setResponseMessage


        }}>
            {children}
        </MyContext.Provider>
    );
};

export { MyContext, MyContextProvider };