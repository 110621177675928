import React, {useEffect, useState} from 'react';
import FilteredEvent from "./FilteredEvent";
import {useParams} from "react-router";
import GuestLayout from "../layout/GuestLayout";
import {useFetch} from "../../hooks/useFetch";
import {usePost} from "../../hooks/usePost";
import axios from "axios";
import {baseUrl} from "../../global/Config";
import {ticketDetails} from "../../storage/TicketDetails";
import LoadingAnimation from "../../components/LoadingAnimation";

function EventByCategory() {

    const {eventCategoryId, eventCategoryName} = useParams();
    const [isSpinning, setIsSpinning] = useState(false);
    //const [responseData] = useFetch(`api/event-category/${eventCategoryId}`);
    //const {isSpinning,responseData} = usePost(`api/event-category/${eventCategoryId}`);
    const [responseData, setResponseData] = useState(null);

    useEffect(() =>{

        const location = JSON.parse(localStorage.getItem("location"));
        const data = JSON.parse(localStorage.getItem("eventFilterData"));
        const requestData = {...location,...data}

        console.log(location)
        console.log(requestData)

        axios.post(`${baseUrl}/api/event/category/${eventCategoryId}`, requestData)
            .then(res => {
                setIsSpinning(false);
                console.log(res.data);
                setResponseData(res.data.events)
                //console.log(res);
            })
            .catch(err => {
                setIsSpinning(false);
                console.log(err);
            });
    },[eventCategoryId])

    return (
        <GuestLayout>
            {isSpinning && <LoadingAnimation/>}
            <br/>
            {eventCategoryName &&
                <h3 className={`custom-margin text-white`} style={{marginBottom:"5px"}}>
                    {eventCategoryName}
                </h3>}
            {responseData &&  <FilteredEvent events = {responseData} />}
        </GuestLayout>
    );
}


export default EventByCategory;