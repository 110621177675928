import axios from "axios";
import {baseUrl, headers} from "../global/Config";

// @ts-ignore
export const getAllEventCategories = (setEventCategories,setIsSpinning) => {
        setIsSpinning(true)
        axios.get(`${baseUrl}/api/event-categories`, headers
        )
            .then(res => {
               // console.log(res)
                setEventCategories(res.data.eventCategories);
                setIsSpinning(false);
            })
            .catch(err => {
                console.log(err);
                setIsSpinning(false);
            });
    }