export const EventRequestData = {
    eventTitle : null,
    eventDescription : null,
    eventVenue : null,
    eventCategoryId : null,
    eventDateStart : null,
    eventDateEnd : null,
    eventTimeStart : null,
    eventTimeEnd : null,
    eventVenueCapacity : null,
    eventImageUrl : null,
    eventVenueImageUrl : null,
    entryMode : null,
    country : "Spain",
    cityName : null,
    cityImageUrl : null,
    latitude : null,
    longitude : null,
    maxTicket : null,
    price : null,
    variablePrices : null,
    variablePriceCaptions : null,
    isPublished : true,
    isBlocked : false,
}
